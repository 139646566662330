/* eslint-disable no-undef */
import React from "react";
import Button from "@mui/material/Button";

const LoginButton: React.FC = () => {
  const handleLogin = () => {
    const authPopupUrl = window.location.origin + "/auth-popup.html";

    Office.context.ui.displayDialogAsync(authPopupUrl, { height: 60, width: 30, displayInIframe: false }, (result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        const dialog = result.value;

        dialog.addEventHandler(Office.EventType.DialogMessageReceived, (args) => {
          // Close the dialog
          dialog.close();

          if ("message" in args) {
            if (args.message === "success") {
              // Authentication was successful
              // Force a re-render of the main app
              window.location.reload();
            } else {
              console.error("Unexpected message:", args.message);
            }
          } else {
            // Handle the error case
            console.error("Dialog error:", args.error);
          }
        });
      } else {
        // Handle the error when the dialog couldn't be displayed
        console.error("Failed to open dialog:", result.error);
      }
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "10px" }}>
      <Button onClick={handleLogin} variant="contained" color="success">
        Log In
      </Button>
    </div>
  );
};

export default LoginButton;
