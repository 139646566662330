import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";

ClosedListSearchBar.propTypes = {
  closedList: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default function ClosedListSearchBar({ closedList, placeholder }) {
  return (
    <Stack spacing={3} sx={{ width: "90%" }}>
      <Autocomplete
        multiple
        id="tags-standard"
        options={closedList}
        renderInput={(params) => <TextField {...params} variant="standard" label={placeholder} />}
      />
    </Stack>
  );
}
