/* eslint-disable no-undef */
import React from "react";
import { SpinningLogo } from "./reusedComponents/SpinningLogo";

export default function Export() {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "20px" }}>
      <SpinningLogo text="Coming Soon!" />
    </div>
  );
}
