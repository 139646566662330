import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "2px", cursor: "pointer" }}
      onClick={() => logout({ logoutParams: { returnTo: window.location.origin + "/index.html" } })}
    >
      <Tooltip title="Logout">
        <LogoutIcon fontSize="small" color="action"/>
      </Tooltip>
    </div>
  );
};

export default LogoutButton;
