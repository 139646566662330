import * as React from "react";
import { makeStyles } from "@fluentui/react-components";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreIcon from "@mui/icons-material/Restore";
import { logAction } from "../../../utils/utils";

const useStyles = makeStyles({
  close_button: {
    marginLeft: "auto",
  },
});

export default function IssueActionButton({ issue, dismissIssue, undo }) {
  const styles = useStyles();

  const handleClick = () => {
    const isIssueOpen = issue.status === "OPEN";
    const actionType = isIssueOpen ? "ISSUE_DISMISSED" : "ISSUE_UNDO";
    const description = `${issue.id}`;
    const additionalData = JSON.stringify(issue);

    if (isIssueOpen) {
      dismissIssue(issue.id);
    } else {
      undo(issue.id);
    }

    logAction(actionType, description, additionalData);
  };

  return (
    <div className={styles.close_button} onClick={handleClick}>
      {issue.status === "OPEN" ? (
        <IconButton size="small">
          <Tooltip title="Dismiss">
            <DeleteIcon fontSize="small" />
          </Tooltip>
        </IconButton>
      ) : (
        <IconButton size="small">
          <Tooltip title="Undo">
            <RestoreIcon fontSize="small" />
          </Tooltip>
        </IconButton>
      )}
    </div>
  );
}