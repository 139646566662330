import * as React from "react";

interface IntNumberInputProps {
  label: string;
  name: string;
  defaultValue?: number;
  min?: number;
  max?: number;
}

export default function IntNumberInput({ label, name, defaultValue = 3, min = 0, max = 100 }: IntNumberInputProps) {
  return (
    <div>
      <p style={{ marginBottom: "2px", paddingBottom: "4px", fontSize: "14px" }}>
        <b>{label}</b>
      </p>
      <input
        type="number"
        name={name}
        defaultValue={defaultValue}
        style={{
          height: "20px",
          width: "15%",
          borderRadius: "10px",
          marginLeft: "auto",
          marginRight: "auto",
          display: "block",
          textAlign: "center",
          border: "0.5px solid rgb(161, 190, 194)",
        }}
        min={min}
        max={max}
      />
    </div>
  );
}
