import { getActiveWorksheetName, getRangeData, getWorkbookData } from "./excelUtils";

/* eslint-disable no-undef */
let serverURL = process.env.REACT_APP_FLASK_SERVER_URL;
console.log("Using server URL:", serverURL);

if (!serverURL) {
  console.error("REACT_APP_FLASK_SERVER_URL is not set!");
}

/**
 *
 * @param selectedRangeJSON - JSON string containing the data in the workbook
 * @returns list of issues found in the workbook represented as JSON objects
 */
export async function getHighlightsFromServer(selectedRangeJSON: string) {
  const highlightCells = await fetchDataFromServer("/get_highlights", selectedRangeJSON);
  return highlightCells;
}

export async function getIssuesFromServer(selectedRangeJSON: string) {
  const issuesList = await fetchDataFromServer("/search", selectedRangeJSON, (data) => {
    for (let issue of data) {
      issue.status = "OPEN";
    }
    return data;
  });

  return issuesList;
}

function prepareRequestData(data: string | object): object {
  let requestData;
  if (typeof data === "string") {
    try {
      requestData = JSON.parse(data);
    } catch (error) {
      console.error("Failed to parse data:", error);
      return null;
    }
  } else {
    requestData = data;
  }

  return requestData;
}

export async function fetchDataFromServer(
  endpoint: string,
  data: string | object,
  postProcess: (data: any) => any = (data) => data
): Promise<any> {
  try {
    const preparedData = prepareRequestData(data);
    if (!preparedData) {
      return [];
    }

    const headers = {
      ...getSessionHeaders(),
      Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    };

    const response = await fetch(serverURL + endpoint, {
      method: "POST",
      headers,
      body: JSON.stringify(preparedData),
    });

    if (!response.ok) {
      console.error(`Server responded with status: ${response.status}`);
      return [];
    }

    const responseData = await response.json();
    return postProcess(responseData);
  } catch (error) {
    console.error("Error in fetchDataFromServer:", error);
    return [];
  }
}

export async function startSession(user) {
  try {
    const response = await fetch(serverURL + "/start_session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        email: user.email,
        username: user.username || user.email,
      }),
    });

    if (!response.ok) {
      console.error("Failed to start session:", response.statusText);
      return null;
    }

    const data = await response.json();
    console.log("Session created:", data);
    if (!data.session_id) {
      console.error("No session_id in response:", data);
      return null;
    }

    return data.session_id;
  } catch (error) {
    console.error("Error starting session:", error);
    return null;
  }
}

export async function getTiles() {
  try {
    const response = await fetch(serverURL + "/get_tiles", {
      method: "GET",
      headers: getSessionHeaders(),
    });
    if (!response.ok) {
      console.error("Failed to get tiles:", response.statusText);
      return null;
    }
    const data = await response.json();
    return data["tiles"];
  } catch (error) {
    console.error("Error getting tiles:", error);
    return null;
  }
}

export async function updateServerOnTileSelection(tileID) {
  try {
    const response = await fetch(serverURL + "/tiles/" + tileID + "/select", {
      method: "POST",
      headers: getSessionHeaders(),
      body: JSON.stringify({}),
    });
    if (!response.ok) {
      console.error("Failed to log action:", response.statusText);
      return null;
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error logging action:", error);
    return null;
  }
}

export async function updateServerOnTileClosure(tileID) {
  try {
    const response = await fetch(serverURL + "/tiles/" + tileID + "/close", {
      method: "POST",
      headers: getSessionHeaders(),
      body: JSON.stringify({}),
    });
    if (!response.ok) {
      console.error("Failed to log action:", response.statusText);
      return null;
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error logging action:", error);
    return null;
  }
}

export async function runTileAction(tileId, tileName, actionName, actionData) {
  try {
    const requestData = {
      actionName: actionName,
      actionData: actionData,
      tileName: tileName,
    };

    const response = await fetch(serverURL + "/tile/" + tileId + "/action", {
      method: "POST",
      headers: getSessionHeaders(),
      body: JSON.stringify(requestData),
    });
    if (!response.ok) {
      console.error("Failed to log action:", response.statusText);
      return null;
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error logging action:", error);
    return null;
  }
}

export async function getStartupData() {
  return getWorkbookData().then(async (workbookData) => {
    let payload = {};
    payload["data"] = workbookData;
    const activeWorksheet = await getActiveWorksheetName();
    payload["activeWorksheet"] = activeWorksheet;
    let payloadJSON = JSON.stringify(payload);
    return await fetchDataFromServer("/startup", payloadJSON);
  });
}

/**
 * Parse the event object and send the relevant data to the server to update the state.
 *
 * See https://learn.microsoft.com/en-us/javascript/api/excel/excel.worksheetchangedeventargs?view=excel-js-preview
 * for more information on the Excel.WorksheetChangedEventArgs object.
 *
 * @param event - Excel.WorksheetChangedEventArgs object
 * @returns true if the event required server update, false otherwise
 */
export async function updateServerOnSpreadsheetChange(event: Excel.WorksheetChangedEventArgs) {
  if (event.triggerSource !== Excel.EventTriggerSource.thisLocalAddin) {
    if (isSupportedChange(event)) {
      return await sendChangeToServer(event);
    } else {
      return await sendWholeSpreadsheetToServer();
    }
  }
  return false;
}

export async function updateServerOnWorksheetAddition(event: Excel.WorksheetAddedEventArgs) {
  try {
    const sheetName = await Excel.run(async (context) => {
      const worksheet = context.workbook.worksheets.getItem(event.worksheetId);
      worksheet.load("name");
      await context.sync();
      return worksheet.name;
    });
    const payload = {
      data: {
        worksheetName: sheetName,
      },
      worksheetId: event.worksheetId,
      address: "",
      changeType: event.type,
      isDifferential: true,
    };
    const payloadJSON = JSON.stringify(payload);
    await fetch(serverURL + "/spreadsheet/change", {
      method: "POST",
      headers: getSessionHeaders(),
      body: payloadJSON,
    });
  } catch (error) {
    console.error("Error updating server on worksheet addition:", error);
  }
}

export async function updateServerOnWorksheetDeletion(event: Excel.WorksheetDeletedEventArgs) {
  try {
    const payload = {
      data: {},
      worksheetId: event.worksheetId,
      address: "",
      changeType: event.type,
      isDifferential: true,
    };
    const payloadJSON = JSON.stringify(payload);
    await fetch(serverURL + "/spreadsheet/change", {
      method: "POST",
      headers: getSessionHeaders(),
      body: payloadJSON,
    });
  } catch (error) {
    console.error("Error updating server on worksheet deletion:", error);
  }
}

/**
 * Check if the event is a standard change that can be sent to the server without the full state.
 *
 * @param event - Excel.WorksheetChangedEventArgs object
 * @returns true if the event is a standard change, false otherwise
 */
function isSupportedChange(event: Excel.WorksheetChangedEventArgs): boolean {
  // TODO: This should be aligned with what the server is capable of processing
  // Over time we should support more change types to reduce network load
  return event.changeType === Excel.DataChangeType.rangeEdited;
}

function getSessionHeaders() {
  const username = localStorage.getItem("username");
  const sessionId = localStorage.getItem("sessionId");
  return {
    "Content-Type": "application/json",
    "X-Username": username,
    "X-Session-ID": sessionId,
  };
}

export async function getSpreadsheetContext() {
  const response = await fetch(serverURL + "/spreadsheet/context", {
    method: "GET",
    headers: getSessionHeaders(),
  });
  const data = await response.json();
  return data;
}

async function sendChangeToServer(event: Excel.WorksheetChangedEventArgs) {
  let payloadJSON = "";
  await Excel.run(async (context) => {
    const range = event.getRangeOrNullObject(context);
    const rangeData = await getRangeData(context, range);
    const payload = {
      data: rangeData,
      worksheetId: event.worksheetId,
      address: event.address,
      changeType: event.changeType,
      isDifferential: true,
    };
    payloadJSON = JSON.stringify(payload);
    try {
      const response = await fetch(serverURL + "/spreadsheet/change", {
        method: "POST",
        headers: getSessionHeaders(),
        body: payloadJSON,
      });
      if (!response.ok) {
        console.error("Failed to log action:", response.statusText);
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.error("Error logging action:", error);
      return false;
    }
  });
}

export async function sendWholeSpreadsheetToServer() {
  const workbookData = await getWorkbookData();
  const payload = {
    data: workbookData,
    worksheetId: "",
    address: "",
    changeType: "",
    isDifferential: false,
  };
  const payloadJSON = JSON.stringify(payload);

  try {
    const response = await fetch(serverURL + "/spreadsheet/change", {
      method: "POST",
      headers: getSessionHeaders(),
      body: payloadJSON,
    });
    if (!response.ok) {
      console.error("Failed to log action:", response.statusText);
      return false;
    } else {
      return true;
    }
  } catch (error) {
    console.error("Error logging action:", error);
    return false;
  }
}
