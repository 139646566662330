import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

export default function SearchBar({ options = companies, label = "Search companies" }) {
  return (
    <Stack spacing={3} sx={{ width: "90%" }}>
      <Autocomplete
        multiple
        id="tags-standard"
        options={options}
        // getOptionLabel={(option) => option.title}
        renderInput={(params) => <TextField {...params} variant="standard" label={label} />}
      />
    </Stack>
  );
}

export const companies = [
  "3M Company",
  "Abbott Laboratories",
  "AbbVie Inc.",
  "Alphabet Inc.",
  "Amazon.com Inc",
  "Apple Inc.",
  "Microsoft Corporation",
  "JPMorgan Chase & Co.",
  "Johnson & Johnson",
  "Walmart Inc.",
  "Visa Inc.",
  "Procter & Gamble Company",
  "NVIDIA Corporation",
  "Mastercard Incorporated",
  "UnitedHealth Group Incorporated",
];

export const statements = ["Income Statement", "Balance Sheet", "Cash Flow"];
