/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import * as React from "react";
import { makeStyles } from "@fluentui/react-components";
import { replaceCellsData } from "../../utils/excelUtils";
import CheckboxSelection from "../../reusedComponents/CheckboxSelection";
import TileSubmissionButton from "../../reusedComponents/TileSubmissionButton";

const useStyles = makeStyles({
  formulas_comp: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginTop: "10px",
    marginLeft: "10px",
  },
});

export function HowIsItCalculated({ activeWorksheet, runTileActionHook, insertDataHook }) {
  const styles = useStyles();
  const [formulas, setFormulas] = React.useState({});

  React.useEffect(() => {
    // Fetch formulas when component mounts
    runTileActionHook("get_formulate_suggestions", {}).then((result) => {
      setFormulas(result.data);
    });
  }, [runTileActionHook]);

  const loadFormulas = async () => {
    const result = await runTileActionHook("infer", {
      formulas: formulas,
      wizzoFileID: activeWorksheet.id,
    });
    replaceCellsData(result.data.cellsContnet, result.data.cellsFormats);

    // TODO: Remove this when the data reaplcement above is actually working
    await insertDataHook(
      result.data.fullSpreadsheet,
      "How Is It Calculated",
      "Converted hardcoded numbers to formulas"
    );
  };

  const statements = ["Income Statement", "Balance Sheet", "Cash Flow Statement"];
  return (
    <div className={styles.formulas_comp}>
      <p style={{ margin: "0px" }}>
        <b>Identified Formulas</b>
      </p>
      {statements.map((statement) => (
        <CheckboxSelection
          key={statement}
          title={statement}
          selectionOptions={formulas[statement]?.map((formula) => formula.itemName)}
          tooltips={formulas[statement]?.map((formula) => formula.formula)}
        />
      ))}
      <TileSubmissionButton onClick={loadFormulas} buttonText="Run" />
    </div>
  );
}
