import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { dismissIssue, markResolved, undoDismissalOrResolution, addColorsLayer, removeColorsLayer } from "./utils";

import {
  addWorkbookEventListener,
  addWorksheetChangeEventListeners,
  getActiveWorksheetName,
  getHasModelsFromServer,
  getIssues,
} from "../utils/utils";
import VisualizersPanel from "./VisualizersPanel";

import CircularProgress from "@mui/material/CircularProgress";
import ResovledList from "./lists/ResolvedList";
import DismissedList from "./lists/DismissedList";
import OpenIssuesList from "./lists/OpenIssuesList";
import IssueObject from "./interfaces/IssueObject";

function WaitingForIssues() {
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <p>Wizzo is looking for issues</p>
      <CircularProgress color="success" />
    </div>
  );
}

function NoModelsFound() {
  const smallLogo = require("../../../../assets/wizzo-small-logo64.png");
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "10px" }}>
      <div style={{ textAlign: "center" }}>
        <p>Wizzo couldn't find financial models or statements in this tab, try different tabs!</p>
      </div>
      <img style={{ animation: `spin 4s linear infinite`, margin: "50px" }} src={smallLogo} />
    </div>
  );
}

export default function DebugTab() {
  const [wasLoaded, setWasLoaded] = useState(false);
  const [activeWorksheet, setActiveWorksheet] = useState("");
  const [issuesList, setIssuesList] = useState([]);
  const [bckColorsMemory, setBckColorsMemory] = useState({});
  const [hasModels, setHasModels] = useState({});

  const issuesListRef = useRef(issuesList);
  const bckColorsMemoryRef = useRef(bckColorsMemory);

  const updateActiveWorksheet = () => {
    getActiveWorksheetName().then((activeWorksheetName) => {
      setActiveWorksheet(activeWorksheetName);
    });
  };

  const updateIssues = async () => {
    const newIssues = await getIssues();
    const issuesToKeep: IssueObject[] = issuesListRef.current.filter(
      (issue) => issue.status === "DISMISSED" || issue.status === "RESOLVED"
    );
    const updatedIssues: IssueObject[] = newIssues.concat(issuesToKeep);
    setWasLoaded(true);
    setIssuesList(updatedIssues);
  };

  const udpateHasModels = async () => {
    const newHasModels = await getHasModelsFromServer();
    setHasModels(newHasModels);
  };

  const dismiss = (issueId: string) => setIssuesList(dismissIssue(issuesListRef.current, issueId));
  const resolve = (issueId: string, originalFormulas: {}) =>
    setIssuesList(markResolved(issuesListRef.current, issueId, originalFormulas));
  const undo = (issueId: string) => setIssuesList(undoDismissalOrResolution(issuesListRef.current, issueId));
  const addColors = (newColors) => setBckColorsMemory(addColorsLayer(bckColorsMemoryRef.current, newColors));
  const removeColors = (cells, colorToRemove) => {
    const [updatedState, newTopLayer] = removeColorsLayer(bckColorsMemoryRef.current, cells, colorToRemove);
    setBckColorsMemory(updatedState);
    return newTopLayer;
  };

  useEffect(() => {
    issuesListRef.current = issuesList;
  }, [issuesList]);

  useEffect(() => {
    bckColorsMemoryRef.current = bckColorsMemory;
  }, [bckColorsMemory]);

  useEffect(() => {
    updateActiveWorksheet();
    udpateHasModels();
    updateIssues();
    addWorkbookEventListener(updateIssues);
    addWorksheetChangeEventListeners(updateActiveWorksheet);
  }, []);

  if (wasLoaded && hasModels[activeWorksheet]) {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <VisualizersPanel
          activeWorksheet={activeWorksheet}
          addColorsLayer={addColors}
          removeColorsLayer={removeColors}
        />
        <OpenIssuesList
          issues={issuesList}
          activeWorksheet={activeWorksheet}
          dismissIssue={dismiss}
          markResolved={resolve}
          undo={undo}
          addColorsLayer={addColors}
          removeColorsLayer={removeColors}
          updateIssues={updateIssues}
        />
        <ResovledList
          issues={issuesList}
          activeWorksheet={activeWorksheet}
          dismissIssue={dismiss}
          markResolved={resolve}
          undo={undo}
        />
        <DismissedList
          issues={issuesList}
          activeWorksheet={activeWorksheet}
          dismissIssue={dismiss}
          markResolved={resolve}
          undo={undo}
        />
      </div>
    );
  } else if (wasLoaded && !hasModels[activeWorksheet]) {
    return <NoModelsFound />;
  } else {
    return <WaitingForIssues />;
  }
}
