/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { useState } from "react";
import TileSubmissionButton from "../../reusedComponents/TileSubmissionButton";
import DroplistSelection, { DroplistOption } from "../../reusedComponents/DroplistSelection";

export function UploadFromFileTile({ runTileActionHook, insertDataHook }) {
  const [source, setSource] = useState<string>("");
  const [files, setFiles] = useState<DroplistOption[]>([]);
  const [selectedFile, setSelectedFile] = useState<string>("");
  const inputFile = React.useRef<HTMLInputElement>(null);
  const [sourceOptions, setSourceOptions] = useState<DroplistOption[]>([]);

  const handleSourceChange = async (option: string) => {
    console.log("option", option);
    if (!option) return;
    setSource(option);
    setSelectedFile("");

    if (option === "computer") {
      inputFile.current?.click();
      return;
    }

    // Fetch files from server based on selected source
    const response = await runTileActionHook("get_files", { source: option });
    const fileOptions = response.data.files.map((file: string) => ({
      value: file,
      label: file,
    }));
    setFiles(fileOptions);
  };

  const handleFileChange = (option: string) => {
    if (!option) return;
    setSelectedFile(option);
  };

  const handleUpload = async () => {
    if (!selectedFile) return;
    const result = await runTileActionHook("upload", {
      source,
      fileName: selectedFile,
    });
    await insertDataHook(result.data, "Model Upload", "Uploaded model from a PDF file");
  };

  React.useEffect(() => {
    runTileActionHook("get_sources", {}).then((response) => {
      setSourceOptions(response.data.sources);
    });
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px", alignItems: "left", width: "100%" }}>
      <DroplistSelection
        title="Select Source"
        options={sourceOptions}
        name="source"
        defaultValue={null}
        handleChange={handleSourceChange}
        width="40vw"
      />

      {(source === "dataroom" || source === "onedrive") && (
        <DroplistSelection
          title="Select File"
          options={files}
          name="file"
          handleChange={handleFileChange}
          width="75vw"
        />
      )}
      <div style={{ marginLeft: "48px" }}>
        <input
          type="file"
          id="file"
          ref={inputFile}
          style={{
            display: source === "computer" ? "block" : "none",
            margin: "0 auto",
            textAlign: "center",
            width: "fit-content",
          }}
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file) {
              setSelectedFile(file.name);
            }
          }}
        />
      </div>

      <TileSubmissionButton buttonText="Upload" onClick={handleUpload} />
    </div>
  );
}
