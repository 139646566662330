/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import * as React from "react";
import { makeStyles } from "@fluentui/react-components";
import ClosedListSearchBar from "../../reusedComponents/ClosedListSearchBar";

import CheckboxMultiSelection from "../../reusedComponents/CheckboxSelection";
import TileSubmissionButton from "../../reusedComponents/TileSubmissionButton";
import RadioButtonGroup from "../../reusedComponents/RadioButtonGroup";
import IntNumberInput from "../../reusedComponents/IntNumberInput";
import DroplistSelection from "../../reusedComponents/DroplistSelection";

const useStyles = makeStyles({
  hist_importer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginTop: "10px",
    marginLeft: "10px",
  },
});

export const statements = ["Income Statement", "Balance Sheet", "Cash Flow"];

export default function PullHistoricals({ runTileActionHook, insertDataHook }) {
  const styles = useStyles();
  const [companies, setCompanies] = React.useState([]);

  React.useEffect(() => {
    runTileActionHook("get_companies", {}).then((result) => {
      setCompanies(result["data"]["companies"]);
    });
  }, []);

  const loadHistoricals = async () => {
    const result = await runTileActionHook("pull", {});
    await insertDataHook(result.data, "Pull Historicals", "Pulled the historicals of AAPL");
  };

  // TODO: actually send the server the selected parameters
  return (
    <div className={styles.hist_importer}>
      <ClosedListSearchBar closedList={companies} placeholder="Search companies" />
      <CheckboxMultiSelection selectionOptions={statements} title="Select Statements" />
      <RadioButtonGroup
        title="Choose Data Frequency"
        options={[
          { value: "q", label: "Quarterly" },
          { value: "a", label: "Annual" },
        ]}
        name="periods"
        defaultValue="q"
      />
      <IntNumberInput label="Number of Periods" name="periods" defaultValue={3} min={1} max={10} />
      <DroplistSelection
        title="Available Sources"
        options={[
          { value: "sec", label: "BamSEC" },
          { value: "va", label: "Visible Alpha" },
          { value: "fastset", label: "FactSet" },
          { value: "bloomberg", label: "Bloomberg" },
        ]}
        name="selectedDataSource"
      />
      <TileSubmissionButton onClick={loadHistoricals} buttonText="Pull Data" />
    </div>
  );
}
