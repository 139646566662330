import * as React from "react";
import { useState } from "react";

import ArchivedListHeader from "../headers/ArchivedListHeader";
import ListBody from "./ListBody";


export default function ArchivedList({ listName, issues, dismissIssue, markResolved, undo }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <ArchivedListHeader listName={listName} issues={issues} isOpen={isOpen} setIsOpen={setIsOpen} />
      <div style={{ marginLeft: "10px" }}>
        {isOpen && <ListBody issues={issues} dismissIssue={dismissIssue} markResolved={markResolved} undo={undo} />}
      </div>
    </div>
  );
}
