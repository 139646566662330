import * as React from "react";

import RefreshIcon from "@mui/icons-material/Refresh";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  spinner: {
    marginRight: "4px",
    marginTop: "2px",
  },
  refersh: {
    marginRight: "8px",
    marginTop: "2px",
  },
});

interface RefreshButtonProps {
  onRefresh: () => Promise<void>;
}

export default function RefreshButton({ onRefresh }: RefreshButtonProps) {
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const refresh = async () => {
    setLoading(true);
    await onRefresh();
    setLoading(false);
  };
  return (
    <div onClick={async () => refresh()}>
      {loading ? (
        <div className={classes.spinner}>
          <CircularProgress color="inherit" size="18px" />
        </div>
      ) : (
        <div className={classes.refersh}>
          <Tooltip title="Refresh">
            <RefreshIcon fontSize="small" />
          </Tooltip>
        </div>
      )}
    </div>
  );
}
