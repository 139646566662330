import * as React from "react";
import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  openCloseList: {
    cursor: "pointer",
  },
});

export function ListOpenCloseIcon({ isOpen, setIsOpen }) {
  const styles = useStyles();
  return isOpen ? (
    <div className={styles.openCloseList}>
      <span
        className="material-symbols-outlined"
        onClick={async () => {
          setIsOpen(false);
        }}
      >
        keyboard_arrow_up
      </span>
    </div>
  ) : (
    <div className={styles.openCloseList}>
      <span
        className="material-symbols-outlined"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        keyboard_arrow_down
      </span>
    </div>
  );
}
