import React from "react";
import { useEffect, useRef, useState } from "react";

import {
  applyWhenWorksheetDeactivated,
  colorCells,
  colorCellsInSingleColor,
  expandRange,
  getCellsColors,
  removeWhenWorksheetDeactivatedEvent,
} from "../../../../utils/excelUtils";
import ListHeader from "../headers/ListHeader";
import ListBody from "./ListBody";

export default function CategoryList({
  listName,
  highlightingColor,
  issues,
  dismissIssue,
  markResolved,
  undo,
  activeWorksheet,
  addColorsLayer,
  removeColorsLayer,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isSwitched, setIsSwitched] = useState(false);
  const isSwitchedRef = useRef(isSwitched);
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    isSwitchedRef.current = isSwitched;
  }, [isSwitched]);

  const closingAlert = (event) => {
    if (isSwitchedRef.current) {
      event.preventDefault();
      event.returnValue = "could be anithing, the browser won't show it";
    }
  };

  const resetColors = () => {
    const cells = issues
      .map((issue) =>
        issue.cellsRange.includes(":")
          ? expandRange(issue.cellsRange).map((cell) => issue.sheet_name + "!" + cell)
          : issue.sheet_name + "!" + issue.cellsRange
      )
      .flat();
    let colors = removeColorsLayer(cells, highlightingColor);
    colors = colors.map((color) => (color == "#ffffff" ? null : color));
    colorCells(cells, colors, activeWorksheet).then(() => {
      setIsSwitched(false);
    });
    window.removeEventListener("beforeunload", closingAlert);
  };

  const onSwitchOn = async () => {
    setIsWaiting(true);
    const cells = issues.map((issue) => issue.sheet_name + "!" + issue.cellsRange);
    getCellsColors(cells, activeWorksheet).then((bckColors: {}) => {
      addColorsLayer(bckColors);
      colorCellsInSingleColor(cells, highlightingColor, activeWorksheet).then(() => {
        setTimeout(() => {
          setIsSwitched(true);
          setIsWaiting(false);
        }, 500);
        const newColors = {};
        cells.forEach((cell) => {
          newColors[cell] = highlightingColor;
        });
        addColorsLayer(newColors);
      });
    });
    applyWhenWorksheetDeactivated(activeWorksheet, resetColors);
    window.addEventListener("beforeunload", closingAlert);
  };

  const onSwitchOff = async () => {
    setIsWaiting(true);

    const cells = issues
      .map((issue) =>
        issue.cellsRange.includes(":")
          ? expandRange(issue.cellsRange).map((cell) => issue.sheet_name + "!" + cell)
          : issue.sheet_name + "!" + issue.cellsRange
      )
      .flat();

    let colors = removeColorsLayer(cells, highlightingColor);
    colors = colors.map((color) => (color == "#ffffff" ? null : color));
    colorCells(cells, colors, activeWorksheet).then(() => {
      setTimeout(() => {
        setIsWaiting(false);
        setIsSwitched(false);
      }, 500);
    });
    removeWhenWorksheetDeactivatedEvent(activeWorksheet, resetColors);
    window.removeEventListener("beforeunload", closingAlert);
  };

  return (
    <div>
      <ListHeader
        listName={listName}
        issues={issues}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isSwitched={isSwitched}
        isWaiting={isWaiting}
        onSwitchOn={onSwitchOn}
        onSwitchOff={onSwitchOff}
      />
      {isOpen && <ListBody issues={issues} dismissIssue={dismissIssue} markResolved={markResolved} undo={undo} />}
    </div>
  );
}
