import * as React from "react";

import { ListOpenCloseIcon } from "../../../../reusedComponents/ListOpenCloseIcon";
import { Counter } from "../../../../reusedComponents/Counter";
import { useCommonStyles } from "../../../../reusedComponents/commonStyles";

export default function ArchivedListHeader({ listName, issues, isOpen, setIsOpen }) {
  const styles = useCommonStyles();
  return (
    <div className={styles.secondary__list__header}>
      <h4 style={{ marginRight: "auto" }}>{listName}</h4>
      <Counter list={issues} />
      <ListOpenCloseIcon isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
}