import * as React from "react";
import ArchivedList from "./interfaces/ArchivedList";

export default function DismissedList({ issues, activeWorksheet, dismissIssue, markResolved, undo }) {
  const dismissedIssuesInSheet = issues.filter(
    (issue) => issue.status === "DISMISSED" && issue.sheet_name === activeWorksheet
  );

  return (
    <ArchivedList
      listName="Dismissed"
      issues={dismissedIssuesInSheet}
      dismissIssue={dismissIssue}
      markResolved={markResolved}
      undo={undo}
    />
  );
}
