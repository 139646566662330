/* eslint-disable no-undef */
import React from "react";

import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  contextParagraph: {
    margin: "10px",
    fontSize: "14px",
    color: "#2d3436",
    padding: "12px",
    borderRadius: "12px",
    backgroundColor: "#f8f9fa",
    boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    lineHeight: "1.6",
    letterSpacing: "0.2px",
    border: "1px solid rgba(0, 0, 0, 0.05)",
    transition: "all 0.2s ease-in-out",
    ":hover": {
      boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
      transform: "translateY(-2px)",
    },
  },
  cursor: {
    borderRight: "2px solid black",
    animation: "blink 1s step-end infinite",
    paddingRight: "2px",
  },
});

function useTypewriter(text: string, interval: number = 50) {
  const [displayText, setDisplayText] = React.useState("");
  const [isComplete, setIsComplete] = React.useState(false);

  React.useEffect(() => {
    setDisplayText(""); // Reset when text changes
    setIsComplete(false); // Reset completion state
    let i = 0;
    const timer = setInterval(() => {
      if (i < text.length) {
        const char = text.charAt(i);
        i++;
        setDisplayText((prev) => prev + char);
      } else {
        clearInterval(timer);
        setIsComplete(true); // Set to complete when done
      }
    }, interval);

    return () => clearInterval(timer);
  }, [text, interval]);

  return { displayText, isComplete };
}

interface ContextParagraphProps {
  setIsContextParagraphCompleted: (completed: boolean) => void;
  spreadsheetContext: any;
}

export function ContextParagraph({ spreadsheetContext, setIsContextParagraphCompleted }: ContextParagraphProps) {
  const [contextToDisplay, setContextToDisplay] = React.useState("");
  const { displayText, isComplete } = useTypewriter(contextToDisplay, 30);
  const styles = useStyles();

  React.useEffect(() => {
    if (isComplete) {
      setIsContextParagraphCompleted(true);
    }
  }, [isComplete]);

  React.useEffect(() => {
    let contextString = "";
    if (!spreadsheetContext.historical_columns || spreadsheetContext.is_empty) {
      contextString = `Hey ${localStorage.getItem("firstName")}! Welcome to Wizzo! It seems like you need some data to kick it off. Below are tiles recommendations to get you started:`;
    } else if (spreadsheetContext.historical_columns.length > 0 && spreadsheetContext.forecast_columns.length == 0) {
      contextString = `You have some historical data in your workbook. Here are some recommendations for the next steps:`;
    } else if (spreadsheetContext.forecast_columns.length > 0) {
      contextString = `Now you have some forecasts. Here are some recommendations for the next steps:`;
    } else {
      contextString = `Recommended tiles:`;
    }
    if (contextString !== contextToDisplay) {
      setContextToDisplay(contextString);
    }
  }, [spreadsheetContext]);

  return (
    <div className={styles.contextParagraph}>
      <div>
        {displayText}
        {!isComplete && <span className={styles.cursor}></span>}
      </div>
    </div>
  );
}
