import * as React from "react";
import { makeStyles } from "@fluentui/react-components";
import { logAction } from "../../../utils/utils";

const useStyles = makeStyles({
  quick_fix: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    cursor: "pointer",
    alignItems: "center",
    paddingRight: "2px",
    "&:hover": {
      color: "green",
    },
  },
});

export default function QuickFix({ issue, markResolved }) {
  const styles = useStyles();
  const [isHovered, setIsHovered] = React.useState(false);

  const fixIssue = () => {
    if (issue.quickFixSuggestion === undefined) {
      return;
    }
    let originalFormulas = {};
    Excel.run(async (context) => {
      for (const [cellIndex, suggestedFormula] of Object.entries(issue.quickFixSuggestion)) {
        const worksheetName = cellIndex.split("!")[0];
        const cellAddress = cellIndex.split("!")[1];
        let range = context.workbook.worksheets.getItem(worksheetName).getRange(cellAddress);
        range.load("formulas");
        await context.sync();
        originalFormulas[cellIndex] = range.formulas[0][0];
        range.formulas = [[suggestedFormula]];
      }
      await context.sync();
    }).then(() => {
      markResolved(issue.id, originalFormulas);
      const actionType = "QUICK_FIX_APPLIED";
      const description = `Applied quick fix to issue ID: ${issue.id}`;
      const additionalData = JSON.stringify(issue);
      logAction(actionType, description, additionalData);
    });
  };

  return (
    <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <div className={styles.quick_fix} onClick={fixIssue}>
        <div className="material-symbols-outlined">check_small</div>
        {isHovered && <p>Fix</p>}
        {isHovered && <p style={{ marginRight: "auto" }}>{issue.quickFixMsg}</p>}
      </div>
    </div>
  );
}
