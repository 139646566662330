/* eslint-disable no-undef */
import React from "react";
import { makeStyles } from "@fluentui/react-components";
import wizzoLogo from "../../../../assets/wizzo-small-logo.png";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "16px",
  },
  spinningLogo: {
    animation: "spin 2s linear infinite",
    width: "40px",
    height: "40px",
  },
  text: {
    color: "#6c757d",
    fontSize: "14px",
    fontWeight: "500",
  },
});

interface SpinningLogoProps {
  text?: string;
}

export function SpinningLogo({ text }: SpinningLogoProps) {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <img src={wizzoLogo} className={styles.spinningLogo} alt="Wizzo Logo" />
      {text && <span className={styles.text}>{text}</span>}
    </div>
  );
}
