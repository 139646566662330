/* eslint-disable no-undef */
import { makeStyles } from "@fluentui/react-components";
import React, { useEffect, useRef, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import Switcher from "../../reusedComponents/Switcher";
import {
  applyWhenWorksheetDeactivated,
  colorCells,
  colorCellsInSingleColor,
  getCellsColors,
  removeWhenWorksheetDeactivatedEvent,
} from "../../utils/excelUtils";

const useStyles = makeStyles({
  visualizer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: "15%",
    marginRight: "15%",
    minHeight: "34px",
    "& p": {
      margin: "0",
      padding: "0",
      fontSize: "13px",
      fontWeight: "bold",
      color: "#0e1608",
    },
  },
});

interface VisualizerProps {
  title: string;
  cells: string[];
  description: string;
  color: string;
  activeWorksheet: string;
  wait: boolean;
  addColorsLayer: (colors: any) => void;
  removeColorsLayer: (cells: string[], color: string) => string[];
}

export default function Visualizer({
  title,
  cells,
  description,
  color,
  activeWorksheet,
  wait,
  addColorsLayer,
  removeColorsLayer,
}: VisualizerProps) {
  const classes = useStyles();
  const [isSwitched, setIsSwitched] = useState(false);
  const isSwitchedRef = useRef(isSwitched);
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    isSwitchedRef.current = isSwitched;
  }, [isSwitched]);

  const closingAlert = (event) => {
    if (isSwitchedRef.current) {
      event.preventDefault();
      event.returnValue = "could be anithing, the browser won't show it";
    }
  };

  const switchOn = () => {
    setIsWaiting(true);
    getCellsColors(cells, activeWorksheet).then((originalBcks: {}) => {
      addColorsLayer(originalBcks);
      colorCellsInSingleColor(cells, color, activeWorksheet).then(() => {
        setIsWaiting(false);
        setIsSwitched(true);
        const newColors = {};
        cells.forEach((cell) => {
          newColors[cell] = color;
        });
        addColorsLayer(newColors);
      });
    });
    applyWhenWorksheetDeactivated(activeWorksheet, resetColors);
    window.addEventListener("beforeunload", closingAlert);
  };

  const resetColors = () => {
    let colors = removeColorsLayer(cells, color);
    colors = colors.map((color) => (color == "#ffffff" ? null : color));
    colorCells(cells, colors, activeWorksheet).then(() => {
      setIsSwitched(false);
    });
    window.removeEventListener("beforeunload", closingAlert);
  };

  const switchOff = () => {
    setIsWaiting(true);
    let colors = removeColorsLayer(cells, color);
    colors = colors.map((color) => (color == "#ffffff" ? null : color));
    colorCells(cells, colors, activeWorksheet).then(() => {
      setTimeout(() => {
        setIsWaiting(false);
        setIsSwitched(false);
      }, 500);
    });
    removeWhenWorksheetDeactivatedEvent(activeWorksheet, resetColors);
    window.removeEventListener("beforeunload", closingAlert);
  };

  return (
    <div className={classes.visualizer}>
      <Tooltip title={description} style={{ marginBottom: "2px", marginRight: "6px" }}>
        <InfoOutlinedIcon sx={{ fontSize: "16px" }} />
      </Tooltip>
      <p>{title}</p>
      <div style={{ marginLeft: "auto" }}>
        {wait || isWaiting ? (
          <CircularProgress color="success" size="20px" sx={{ padding: "0px", marginLeft: "4px" }} />
        ) : (
          <Switcher
            value={isSwitched}
            onClick={() => {
              isSwitched ? switchOff() : switchOn();
            }}
          />
        )}
      </div>
    </div>
  );
}
