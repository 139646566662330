import * as React from "react";

interface RadioOption {
  value: string;
  label: string;
}

interface RadioButtonGroupProps {
  title: string;
  options: Array<{ value: string; label: string }>;
  name: string;
  defaultValue: string;
  handleOnClick?: (value: string) => void;
}

export default function RadioButtonGroup({
  title,
  options,
  name,
  defaultValue,
  handleOnClick = (_: string) => {},
}: RadioButtonGroupProps) {
  return (
    <div>
      <p style={{ marginBottom: "2px", paddingBottom: "4px", fontSize: "14px" }}>
        <b>{title}</b>
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          justifyContent: "center",
        }}
      >
        {options.map((option) => (
          <label key={option.value}>
            <input
              type="radio"
              name={name}
              value={option.value}
              defaultChecked={option.value === defaultValue}
              onClick={() => handleOnClick(option.value)}
            />
            {option.label}
          </label>
        ))}
      </div>
    </div>
  );
}
