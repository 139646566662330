import * as React from "react";

export interface DroplistOption {
  value: string;
  label: string;
}

interface DroplistSelectionProps {
  title: string;
  options: DroplistOption[];
  name: string;
  defaultValue?: string;
  handleChange?: (option: string) => void;
  width?: string;
}

export default function DroplistSelection({
  title,
  options,
  name,
  defaultValue,
  handleChange,
  width = "40%",
}: DroplistSelectionProps) {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
      <p style={{ margin: "4px", fontSize: "14px" }}>
        <b>{title}</b>
      </p>
      <select
        name={name}
        style={{
          height: "30px",
          width: width,
          borderRadius: "10px",
          marginLeft: "auto",
          marginRight: "auto",
          paddingLeft: "10px",
        }}
        defaultValue={defaultValue}
        onChange={(e) => handleChange?.(e.target.value)}
      >
        {options?.length > 0 ? (
          options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))
        ) : (
          <option value="">Loading...</option>
        )}
      </select>
    </div>
  );
}
