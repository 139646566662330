/* eslint-disable no-undef */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@fluentui/react-components";
import { LoadingSpinner } from "./LoadingSpinner";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    marginTop: "10px",
  },
  button: {
    fontSize: "13px",
    height: "30px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    fontWeight: "bold",
    fontFamily: "inherit",
    backgroundColor: "#42BDFF",
    color: "white",
    borderRadius: "25px",
    border: "0px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#3aa8e6",
    },
  },
});

TileSubmissionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

export default function TileSubmissionButton({ onClick, buttonText = "Submit" }) {
  const classes = useStyles();
  const [isWaiting, setIsWaiting] = React.useState(false);

  const handleClick = async () => {
    setIsWaiting(true);
    await onClick();
    setIsWaiting(false);
  };

  return (
    <div className={classes.container}>
      {isWaiting ? (
        <LoadingSpinner />
      ) : (
        <button className={classes.button} onClick={handleClick}>
          {buttonText}
        </button>
      )}
    </div>
  );
}
