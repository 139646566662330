import { makeStyles } from "@fluentui/react-components";

export const useCommonStyles = makeStyles({
  list__header: {
    display: "flex",
    flexDirection: "row",
    marginTop: "4px",
    marginBottom: "4px",
    backgroundColor: "white",
    borderRadius: "10px",
    border: "1px solid #bdbdbd",
    height: "30px",
    alignItems: "center",
    "& h4": {
      marginLeft: "6px",
      fontSize: "14px",
      paddingLeft: "4px",
    },
    "& span": {
      margin: "6px",
    },
  },
  secondary__list__header: {
    display: "flex",
    flexDirection: "row",
    marginTop: "5px",
    height: "30px",
    alignItems: "center",
    "& h4": {
      marginLeft: "6px",
      fontSize: "14px",
      paddingLeft: "4px",
      color: "gray",
    },
    "& Counter, & span": {
      margin: "6px",
    },
  },
  list__item: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "1px",
    marginTop: "1px",
    borderRadius: "5px",
    paddingLeft: "5px",
    paddingRight: "5px",
    backgroundColor: "white",
    border: "1px solid #bdbdbd",
    "&:hover, &:focus": {
      border: "2px solid #bdbdbd",
    },
  },
  noBulletpoints: {
    width: "100%",
    listStyleType: "none",
    margin: "0",
    padding: "0",
  },
  item__oneliner: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "4px",
    "& h4, & p": {
      margin: "0px",
      padding: "0px",
      fontSize: "14px",
    },
  },
  item__content: {
    display: "flex",
    flexDirection: "column",
    margin: "0px",
    width: "100%",
    "& p": {
      fontSize: "14px",
      margin: "2px",
      padding: "2px",
    },
  },
});
