import * as React from "react";
import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  numberCircleStyle: {
    borderRadius: "50%",
    width: "14px",
    height: "14px",
    padding: "2px",
    marginLeft: "8px",
    color: "white",
    textAlign: "center",
    font: "12px Arial, sans-serif",
    backgroundColor: "black",
  },
  highNumberCircleStyle: {
    borderRadius: "50%",
    width: "16px",
    height: "14px",
    padding: "2px",
    marginLeft: "8px",
    color: "white",
    textAlign: "center",
    font: "12px Arial, sans-serif",
    backgroundColor: "#FF4C4C",
  },
});

export function Counter({ list }) {
  const styles = useStyles();
  const number = list.length > 9 ? "9+" : String(list.length);
  return <div className={list.length > 9 ? styles.highNumberCircleStyle : styles.numberCircleStyle}>{number}</div>;
}
