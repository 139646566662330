import * as React from "react";
import { useEffect, useState } from "react";

import { makeStyles } from "@fluentui/react-components";

import Visualizer from "./Visualizer";
import { addWorkbookEventListener, getActiveWorksheetName } from "../../utils/excelUtils";
import { getHighlights } from "../debugger/debuggerUtils";

const visualizersDescriptions = {
  hardcoded: "In-model cells with hardcoded values",
  assumptions: "Cells that are used as assumptions in the model",
  patternBreaking: "Formula cells that break the row pattern",
};

const useStyles = makeStyles({
  panel: {
    borderRadius: "10px",
    leftPadding: "10px",
    marginTop: "4px",
  },
});

interface VisualizersPanelProps {
  tileId: string;
  addColors: (colors: any) => void;
  removeColors: (cells: string[], color: string) => string[];
}

export function VisualizersPanel({ tileId, addColors, removeColors }: VisualizersPanelProps) {
  const [activeWorksheet, setActiveWorksheet] = useState("");
  const [hardcodedCells, setHardcodedCells] = React.useState([]);
  const [assumptionsCells, setAssumptionsCells] = React.useState([]);
  const [patternBreakingCells, setPatternBreakingCells] = React.useState([]);

  const [waitForHighlights, setWaitForHighlights] = React.useState(true);
  const classes = useStyles();

  const updateHighlights = async () => {
    const worksheet = await getActiveWorksheetName();
    setActiveWorksheet(worksheet);
    setWaitForHighlights(true);

    getHighlights(tileId).then((response) => {
      // Extract cells from response.data if it exists, otherwise use response directly
      const cells = response.data || response;

      if (!cells || Object.keys(cells).length === 0) {
        setHardcodedCells([]);
        setAssumptionsCells([]);
        setPatternBreakingCells([]);
        setWaitForHighlights(false);
        return;
      }

      setHardcodedCells(cells["HardCoded"] || []);
      setAssumptionsCells(cells["Assumptions"] || []);
      setPatternBreakingCells(cells["FormulaMisalignmentIssue"] || []);
      setWaitForHighlights(false);
    });
  };

  useEffect(() => {
    addWorkbookEventListener(updateHighlights);
  }, []);

  useEffect(() => {
    (async () => {
      await updateHighlights();
    })();
  }, []);
  const hardcodedCellsInActiveWorksheet = [
    ...hardcodedCells.filter((cell: string) => cell.includes(activeWorksheet)),
    ...assumptionsCells.filter((cell: string) => cell.includes(activeWorksheet)),
  ];
  const patternBreakingCellsInActiveWorksheet = patternBreakingCells.filter((cell: string) =>
    cell.includes(activeWorksheet)
  );

  return (
    <div className={classes.panel}>
      <div>
        <Visualizer
          title="Pattern Disruption"
          cells={patternBreakingCellsInActiveWorksheet}
          description={visualizersDescriptions.patternBreaking}
          color="#7393b3"
          activeWorksheet={activeWorksheet}
          wait={waitForHighlights}
          addColorsLayer={addColors}
          removeColorsLayer={removeColors}
        />
        <Visualizer
          title="Hardcoded Cells"
          cells={hardcodedCellsInActiveWorksheet}
          description={visualizersDescriptions.hardcoded}
          color="#0096ff"
          activeWorksheet={activeWorksheet}
          wait={waitForHighlights}
          addColorsLayer={addColors}
          removeColorsLayer={removeColors}
        />
      </div>
    </div>
  );
}
