import * as React from "react";
import ArchivedList from "./interfaces/ArchivedList";

export default function ResovledList({ issues, activeWorksheet, dismissIssue, markResolved, undo }) {
  const resolvedIssues = issues.filter((issue) => issue.status === "RESOLVED");
  const resolvedIssuesInSheet = resolvedIssues.filter((issue) => issue.sheet_name === activeWorksheet);
  return (
    <ArchivedList
      listName="Resolved"
      issues={resolvedIssuesInSheet}
      dismissIssue={dismissIssue}
      markResolved={markResolved}
      undo={undo}
    />
  );
}
