import React, { useEffect } from "react";
import { makeStyles } from "@fluentui/react-components";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "./utils/LoginButton";
import AppContent from "./AppContent";
import { startSession } from "./utils/utils";


const useStyles = makeStyles({
  logo: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "20px",
    width: "50%",
    height: "20%",
  },
  addin__main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    flex: "1 0 0",
    padding: "10px",
    minHeight: "90vh",
  },
  app_content: {
    minHeight: "80vh",
  },
});

export default function App() {
  const styles = useStyles();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const mainLogo = require("../../../assets/wizzo-logo.png");

  useEffect(() => {
    const initiateSession = async () => {
      if (isAuthenticated && user) {
        const sessionId = await startSession(user);
        if (sessionId) {
          localStorage.setItem("sessionId", sessionId);
          const username = user.username || user.email;
          localStorage.setItem("username", username);
        }
      }
    };
    initiateSession();
  }, [isAuthenticated]);

  return (
    <div className={`${styles.addin__main}`}>
      <img className={styles.logo} src={mainLogo} />
      {isLoading ? (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <p>Wizzo is Logging you in...</p>
          <CircularProgress color="success" />
        </div>
      ) : (
        <div className={styles.app_content}>
          {!isAuthenticated ? (
            <div>
              <LoginButton />
            </div>
          ) : (
            <div className={styles.app_content}>
              <AppContent user={user}/>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
