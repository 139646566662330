import * as React from "react";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";

export default function SeverityIcon({ issue }) {
  if (issue.status === "RESOLVED") {
    return <CheckCircleOutlineIcon fontSize="small" sx={{ color: "#62ac68" }} />;
  }
  if (issue.severity === "ERROR") {
    return <ErrorIcon fontSize="small" sx={{ color: "#dc143c" }} />;
  } else if (issue.severity === "WARNING") {
    return <ErrorIcon fontSize="small" sx={{ color: "#ffc000" }} />;
  } else {
    return <InfoIcon fontSize="small" sx={{ color: "#0078d4" }} />;
  }
}
