import * as React from "react";
import { selectRange } from "../../../utils/excelUtils";

import IssueHeader from "./IssueHeader";
import IssueContent from "./IssueContent";
import { useCommonStyles } from "../../../reusedComponents/commonStyles";

export default function Issue({ issue, dismissIssue, markResolved, undo }) {
  const styles = useCommonStyles();
  const [isOpen, setIsOpen] = React.useState(false);

  const elaborate = () => {
    if (!isOpen) {
      selectRange(issue.cellsRange);
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  return (
    <div className={styles.list__item} onClick={elaborate}>
      <IssueHeader issue={issue} isOpen={isOpen} setIsOpen={setIsOpen} dismissIssue={dismissIssue} undo={undo} />
      {isOpen && <IssueContent issue={issue} markResolved={markResolved} />}
    </div>
  );
}
