import { getActiveWorksheetName, getWorkbookData } from "./excelUtils";
import { fetchDataFromServer, getHighlightsFromServer, getIssuesFromServer } from "./serverUtils";

export const colorCodes = {
  HARDCODED: "#0000ff",
  OTHER_ERRORS: "#ffff99",
  FORMULA_ERRORS: "#bfbfbf",
  LINKING_ERRORS: "#cc0000",
  REFERENCE_ERRORS: "#ac0000",
  FORMAT_ERRORS: "#ff4d4d",
  DATA_ERRORS: "#ff4d4d",
  WARNINGS: "#ffff99",
  HIGHLIGHTS: "#ffff99",
  INSIGHTS: "#a4c9a4",
};

export const categoriesText = {
  OTHER_ERRORS: "Other Errors",
  FORMULA_ERRORS: "Formula Errors",
  LINKING_ERRORS: "Linking Errors",
  FORMAT_ERRORS: "Format Errors",
  REFERENCE_ERRORS: "Reference Errors",
  DATA_ERRORS: "Data Errors",
  WARNINGS: "Warnings",
  HIGHLIGHTS: "Highlights",
  INSIGHTS: "Insights",
};

const issuesToFind = [
  "FormulaMisalignmentIssue",
  "RefColumnNameMismatch",
  "HardCodedInFormula",
  "LinkedWorkbookIssue",
  "PercentageMisformatIssue",
  "MetricMismatch",
  "EmptyCellRefIssue",
];
const issuesToHighlight = ["HardCoded", "FormulaMisalignmentIssue", "Assumptions"];

export async function getIssues() {
  return getWorkbookData().then(async (workbookData) => {
    let payload = {};
    payload["data"] = workbookData;
    const activeWorksheet = await getActiveWorksheetName();
    payload["activeWorksheet"] = activeWorksheet;
    payload["issuesToFind"] = issuesToFind;
    let payloadJSON = JSON.stringify(payload);
    return await getIssuesFromServer(payloadJSON);
  });
}

export async function getHasModelsFromServer() {
  return getWorkbookData().then(async (workbookData) => {
    let payload = {};
    payload["data"] = workbookData;
    let payloadJSON = JSON.stringify(payload);
    return await fetchDataFromServer("/has_models", payloadJSON);
  });
}

export async function getHighlights() {
  return getWorkbookData().then(async (workbookData) => {
    let payload = {};
    payload["data"] = workbookData;
    const activeWorksheet = await getActiveWorksheetName();
    payload["activeWorksheet"] = activeWorksheet;
    payload["issuesToFind"] = issuesToHighlight;
    let payloadJSON = JSON.stringify(payload);
    const highlights = await getHighlightsFromServer(payloadJSON);
    return highlights;
  });
}

export function selectRange(rangeString: string) {
  Excel.run({ delayForCellEdit: true }, async (context) => {
    let range = context.workbook.worksheets.getActiveWorksheet().getRange(rangeString);
    range.select();
    await context.sync();
  });
}
