/* eslint-disable no-undef */
import { getActiveWorksheetName } from "../../utils/excelUtils";
import { fetchDataFromServer } from "../../utils/serverUtils";

export const colorCodes = {
  HARDCODED: "#0000ff",
  OTHER_ERRORS: "#ffff99",
  FORMULA_ERRORS: "#bfbfbf",
  LINKING_ERRORS: "#cc0000",
  REFERENCE_ERRORS: "#ac0000",
  FORMAT_ERRORS: "#ff4d4d",
  DATA_ERRORS: "#ff4d4d",
  WARNINGS: "#ffff99",
  HIGHLIGHTS: "#ffff99",
  INSIGHTS: "#a4c9a4",
};

export const categoriesText = {
  OTHER_ERRORS: "Other Errors",
  FORMULA_ERRORS: "Formula Errors",
  LINKING_ERRORS: "Linking Errors",
  FORMAT_ERRORS: "Format Errors",
  REFERENCE_ERRORS: "Reference Errors",
  DATA_ERRORS: "Data Errors",
  WARNINGS: "Warnings",
  HIGHLIGHTS: "Highlights",
  INSIGHTS: "Insights",
};

const issuesToFind = [
  "FormulaMisalignmentIssue",
  "RefColumnNameMismatch",
  "HardCodedInFormula",
  "LinkedWorkbookIssue",
  "PercentageMisformatIssue",
  "MetricMismatch",
  "EmptyCellRefIssue",
];

const issuesToHighlight = ["HardCoded", "FormulaMisalignmentIssue", "Assumptions"];

export async function getIssues(tileId: string) {
  const payload = {
    issuesToFind: issuesToFind,
  };
  return await fetchDataFromServer(`/tile/${tileId}/action`, {
    tileName: "Debugger",
    actionName: "search",
    actionData: payload,
  });
}

export async function getHighlights(tileId: string) {
  const payload = {
    activeWorksheet: await getActiveWorksheetName(),
    issuesToFind: issuesToHighlight,
  };
  return await fetchDataFromServer(`/tile/${tileId}/action`, {
    username: localStorage.getItem("username"),
    sessionId: localStorage.getItem("sessionId"),
    tileName: "Debugger",
    actionName: "get_highlights",
    actionData: payload,
  });
}
