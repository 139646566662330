import * as React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Switch from "./utils/Switch";
import { ListOpenCloseIcon } from "../../../utils/ListOpenCloseIcon";
import { Counter } from "./utils/Counter";
import { useCommonStyles } from "../../../utils/commonStyles";


export default function ListHeader({
  listName,
  issues,
  isOpen,
  setIsOpen,
  isSwitched,
  isWaiting,
  onSwitchOn,
  onSwitchOff,
}) {
  const styles = useCommonStyles();

  return (
    <div className={styles.list__header}>
      <h4 style={{ marginRight: "auto" }}>{listName}</h4>
      {isWaiting ? (
        <CircularProgress color="success" size="20px" sx={{ padding: "0px", marginLeft: "4px", marginTop: "6px" }} />
      ) : (
        <Switch
          value={isSwitched}
          onClick={() => {
            isSwitched ? onSwitchOff() : onSwitchOn();
          }}
        />
      )}
      <Counter list={issues} />
      <ListOpenCloseIcon isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
}
