import React from "react";

interface WizzoTextInputProps {
  label?: string;
  value: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  type?: string;
}

export default function WizzoTextInput({
  label = "",
  value,
  onChange = () => {},
  placeholder = "",
  type = "text",
}: WizzoTextInputProps) {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
      {label && <label style={{ fontWeight: "600", fontSize: "14px" }}>{label}</label>}
      <input
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        style={{
          width: "50%",
          marginLeft: "auto",
          marginRight: "auto",
          padding: "5px",
          border: "1px solid #ccc",
          borderRadius: "10px",
          fontSize: "14px",
          textAlign: "center",
        }}
        placeholder={placeholder}
      />
    </div>
  );
}
