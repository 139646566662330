import IssueObject from "./interfaces/IssueObject";

export const dismissIssue = (issuesList: IssueObject[], issueId: string) => {
  return issuesList.map((issue) => (issue.id === issueId ? { ...issue, status: "DISMISSED" } : issue));
};

export const markResolved = (issuesList: IssueObject[], issueId: string, originalFormulas: {}) => {
  return issuesList.map((issue) =>
    issue.id === issueId ? { ...issue, originalFormulas: originalFormulas, status: "RESOLVED" } : issue
  );
};

const undoFix = (issue: IssueObject) => {
  Excel.run(async (context) => {
    for (const [cellIndex, originalFormula] of Object.entries(issue.originalFormulas)) {
      const worksheetName = cellIndex.split("!")[0];
      const cellAddress = cellIndex.split("!")[1];
      let range = context.workbook.worksheets.getItem(worksheetName).getRange(cellAddress);
      range.formulas = [[originalFormula]];
    }
    await context.sync();
  });
};

export const undoDismissalOrResolution = (issuesList: IssueObject[], issueId: string) => {
  const issue = issuesList.find((issue) => issue.id === issueId);
  if (issue.status === "RESOLVED") {
    undoFix(issue);
  }
  return issuesList.map((issue) => (issue.id === issueId ? { ...issue, status: "OPEN" } : issue));
};

export const addColorsLayer = (currentColors, newColors) => {
  let newBckColorsMemory = { ...currentColors };
  for (const cell in newColors) {
    if (cell in currentColors) {
      if (!currentColors[cell].includes(newColors[cell].toLowerCase())) {
        currentColors[cell].push(newColors[cell].toLowerCase());
      }
    } else {
      newBckColorsMemory[cell] = [newColors[cell].toLowerCase()];
    }
  }
  return newBckColorsMemory;
};

export const removeColorsLayer = (currentColors, cells, colorToRemove) => {
  let newBckColorsMemory = { ...currentColors };
  const colors = [];
  cells.forEach((cell) => {
    const index = newBckColorsMemory[cell].indexOf(colorToRemove);
    if (index > -1) {
      newBckColorsMemory[cell].splice(index, 1);
    }
    colors.push(newBckColorsMemory[cell].slice(-1)[0]);
  });
  return [newBckColorsMemory, colors];
};
