/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import * as React from "react";
import { ListOpenCloseIcon } from "../../reusedComponents/ListOpenCloseIcon";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import TileSubmissionButton from "../../reusedComponents/TileSubmissionButton";
import { LoadingSpinner } from "../../reusedComponents/LoadingSpinner";
import WizzoTextInput from "../../reusedComponents/WizzoTextInput";
// Add interfaces for type safety
interface AssumptionData {
  value: string | number;
  support: string;
}

interface AssumptionsMap {
  [key: string]: AssumptionData;
}

interface AssumptionProps {
  name: string;
  assumptionValue: string | number;
  support: string;
}

interface AssumptionListProps {
  assumptions: AssumptionsMap;
}

function Assumption({ name, assumptionValue, support }: AssumptionProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [wasOpened, setWasOpened] = React.useState(false);

  React.useEffect(() => {
    if (isOpen) {
      setWasOpened(true);
    }
  }, [isOpen]);

  return (
    <div style={{ backgroundColor: "white", borderRadius: "10px", border: "0.5px solid rgb(201, 214, 222)" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p style={{ margin: "0px", paddingLeft: "6px", fontSize: "14px" }}>{isOpen ? <b>{name}</b> : name}</p>
        {!wasOpened || isOpen ? (
          <BorderColorIcon fontSize="small" style={{ marginLeft: "6px", color: "#004367" }} />
        ) : (
          <CheckCircleIcon fontSize="small" style={{ marginLeft: "6px", color: "#004367" }} />
        )}
        <div style={{ marginTop: "2px", marginRight: "4px", marginLeft: "auto" }}>
          <ListOpenCloseIcon isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
      </div>
      {isOpen && (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "8px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              padding: "0 8px",
            }}
          >
            <p style={{ margin: "0px", padding: "0px", fontSize: "12px" }}>Suggested Value</p>
            <WizzoTextInput value={assumptionValue.toString()} />
          </div>
          <div>
            <p style={{ margin: "0px", padding: "0px", marginBottom: "4px", paddingLeft: "6px", fontSize: "10px" }}>
              <b>Support </b>
              <a href={support} target="_blank" rel="noreferrer">
                {support}
              </a>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

function AssumptionList({ assumptions }: AssumptionListProps) {
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p>
          <b>Required Assumptions</b>
        </p>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        {Object.entries(assumptions).map(([name, data]) => (
          <Assumption key={name} name={name} assumptionValue={data.value} support={data.support} />
        ))}
      </div>
    </div>
  );
}

export function DCF({ activeWorksheet, runTileActionHook, insertDataHook }) {
  const [assumptions, setAssumptions] = React.useState<AssumptionsMap>({});

  React.useEffect(() => {
    const fetchAssumptions = async () => {
      const result = await runTileActionHook("get_assumptions", {});
      if (result?.data) {
        setAssumptions(result.data);
      }
    };
    fetchAssumptions();
  }, []);

  const loadValuation = async () => {
    const result = await runTileActionHook("calculate_dcf", {
      assumptions: assumptions,
      worksheetId: activeWorksheet.id,
    });

    await insertDataHook(result.data, "DCF", "Added a DCF valuation");
  };

  return (
    <div>
      {Object.keys(assumptions).length > 0 ? (
        <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <AssumptionList assumptions={assumptions} />
        </div>
      ) : (
        <LoadingSpinner />
      )}
      <TileSubmissionButton onClick={loadValuation} buttonText="Add Valuation" />
    </div>
  );
}
