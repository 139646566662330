import * as React from "react";
import DebugTab from "./debugging/DebugTab";
import { makeStyles } from "@fluentui/react-components";
import DiamondIcon from "@mui/icons-material/Diamond";
import FeedbackPopup from "./FeedbackPopup";
import WizzoFooterButton from "./utils/WizzoButton";
import LogoutButton from "./utils/LogoutButton";
import AddCommentIcon from "@mui/icons-material/AddComment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import { addWorksheetChangeEventListeners, getActiveWorksheetName, isWorksheetEmpty } from "./utils/excelUtils";
import { addColorsLayer, removeColorsLayer } from "./debugging/utils";
import VisualizersPanel from "./debugging/VisualizersPanel";
import HistoricalsPuller from "./historicals/HistoricalsPuller";
import { FormulaInference } from "./formula_inference/FormulaInference";
import { AddForecasts } from "./add_forecasts/AddForecasts";

const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    minHeight: "85vh",
  },
  buttons_bar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: "10px",
    marginTop: "auto",
    paddingTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "80%",
  },
  search: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: "10px",
    marginTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "90%",
  },
  search_box: {
    width: "100%",
    padding: "8px",
    borderRadius: "25px",
    border: "1px solid #808080",
    fontSize: "15px",
  },
  recommended_tile: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    paddingLeft: "4px",
    paddingRight: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    cursor: "pointer",
    backgroundColor: "white",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "25px",
    border: "1px solid #808080",
    fontSize: "12px",
  },
  recommendations: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "8px",
    marginTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
  },
  open_tiles: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "10px",
    marginTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
  },
  tile_wrapper: {
    borderRadius: "10px",
    paddingBottom: "8px",
    backgroundColor: "#f0f0f0",
  },
  tile_header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#004367",
    color: "white",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    "& h5": {
      fontSize: "16px",
      margin: "6px",
      padding: "0px",
    },
  },
  tile_logic: {
    padding: "4px",
  },
});

const ButtonsBar = ({ userName, userEmail }) => {
  const styles = useStyles();
  const [isFeedbackOpen, setIsFeedbackOpen] = React.useState(false);
  return (
    <div style={{ marginTop: "auto" }}>
      {isFeedbackOpen && (
        <FeedbackPopup setIsFeedbackOpen={setIsFeedbackOpen} userName={userName} userEmail={userEmail} />
      )}
      <div className={styles.buttons_bar}>
        <WizzoFooterButton
          buttonText="Wizzo Pro"
          buttonIcon={<DiamondIcon color="inherit" />}
          action={() => {
            window.open(
              "https://app.visily.ai/projects/9bb26e2d-e5c3-404d-a080-16e2ff3f767a/boards/1438605/presenter?play-mode=Prototype",
              "_blank"
            );
          }}
        />
        <WizzoFooterButton
          buttonText="Feedback"
          buttonIcon={<AddCommentIcon color="inherit" />}
          action={() => {
            setIsFeedbackOpen(true);
          }}
        />
        <LogoutButton />
      </div>
    </div>
  );
};

function SearchBar() {
  const styles = useStyles();
  const tilesOptions = ["Visualizers", "Debugger", "Model Builder"];
  return (
    <div className={styles.search}>
      <input className={styles.search_box} type="text" placeholder="Search Tiles" />
    </div>
  );
}

function RecommendedTile({ title, openTiles, setOpenTiles }) {
  const styles = useStyles();

  const addTile = (tileTitle) => {
    setOpenTiles([...openTiles, tileTitle]);
  };

  return (
    <div className={styles.recommended_tile} onClick={() => addTile(title)}>
      <AddCircleIcon fontSize="small" sx={{ color: "#004367" }} />
      {title}
    </div>
  );
}

function RecommendedTiles({ openTiles, setOpenTiles, activeWorksheet }) {
  const styles = useStyles();
  const [recommendedTiles, setRecommendedTiles] = React.useState([]);

  React.useEffect(() => {
    isWorksheetEmpty(activeWorksheet).then((isEmpty) => {
      if (isEmpty) {
        setRecommendedTiles(["Pull Historicals"]);
      } else {
        setRecommendedTiles(["Visualizers", "Debugger", "Add Forecasts", "Versions", "Formula Inference"]);
      }
    });
  }, [activeWorksheet, openTiles]);

  return (
    <div className={styles.recommendations}>
      {recommendedTiles
        .filter((tile) => !openTiles.includes(tile))
        .map((tile) => (
          <RecommendedTile key={tile} title={tile} openTiles={openTiles} setOpenTiles={setOpenTiles} />
        ))}
    </div>
  );
}

function TileHeader({ title, openTiles, setOpenTiles }) {
  const styles = useStyles();
  const dataTileLogo = require("../../../assets/data-tile-logo.png");
  const buildTileLogo = require("../../../assets/build-tile-logo.png");

  const closeTile = () => {
    setOpenTiles(openTiles.filter((tile) => tile !== title));
  };

  return (
    <div className={styles.tile_header}>
      {title === "Pull Historicals" && (
        <div style={{ marginLeft: "4px" }}>
          <img style={{ width: "24px", height: "30px", color: "white" }} src={dataTileLogo} />
        </div>
      )}
      {(title === "Formula Inference" || title === "Add Forecasts") && (
        <div style={{ marginLeft: "4px" }}>
          <img style={{ width: "24px", height: "30px", color: "white" }} src={buildTileLogo} />
        </div>
      )}
      <h5>{title}</h5>
      <div style={{ marginRight: "6px", marginTop: "6px", cursor: "pointer", marginLeft: "auto" }} onClick={closeTile}>
        <CloseIcon fontSize="medium" />
      </div>
    </div>
  );
}

function OpenTiles({ openTiles, setOpenTiles, activeWorksheet, addColors, removeColors }) {
  const styles = useStyles();

  const tilesMap = {
    Debugger: <DebugTab activeWorksheet={activeWorksheet} addColors={addColors} removeColors={removeColors} />,
    Visualizers: (
      <VisualizersPanel activeWorksheet={activeWorksheet} addColors={addColors} removeColors={removeColors} />
    ),
    "Model Builder": <div>Model Builder</div>,
    "Add Forecasts": <AddForecasts activeWorksheet={activeWorksheet} />,
    Versions: <div>Versions</div>,
    "Pull Historicals": <HistoricalsPuller activeWorksheet={activeWorksheet} />,
    "Formula Inference": <FormulaInference activeWorksheet={activeWorksheet} />,
  };

  return (
    <div className={styles.open_tiles}>
      {openTiles
        .filter((tile) => tile in tilesMap)
        .map((tile) => (
          <div key={tile} className={styles.tile_wrapper}>
            <TileHeader title={tile} openTiles={openTiles} setOpenTiles={setOpenTiles} />
            <div className={styles.tile_logic}>{tilesMap[tile]}</div>
          </div>
        ))}
    </div>
  );
}

export default function AppContent({ user }) {
  const styles = useStyles();
  const [openTiles, setOpenTiles] = React.useState([]);
  const [activeWorksheet, setActiveWorksheet] = React.useState("");
  const [bckColorsMemory, setBckColorsMemory] = React.useState({});
  const bckColorsMemoryRef = React.useRef(bckColorsMemory);

  const updateActiveWorksheet = () => {
    getActiveWorksheetName().then((activeWorksheetName) => {
      setActiveWorksheet(activeWorksheetName);
    });
  };
  const addColors = (newColors) => setBckColorsMemory(addColorsLayer(bckColorsMemoryRef.current, newColors));
  const removeColors = (cells, colorToRemove) => {
    const [updatedState, newTopLayer] = removeColorsLayer(bckColorsMemoryRef.current, cells, colorToRemove);
    setBckColorsMemory(updatedState);
    return newTopLayer;
  };

  React.useEffect(() => {
    bckColorsMemoryRef.current = bckColorsMemory;
  }, [bckColorsMemory]);

  React.useEffect(() => {
    updateActiveWorksheet();
    addWorksheetChangeEventListeners(updateActiveWorksheet);
  }, []);

  return (
    <div className={styles.content}>
      <SearchBar />
      <RecommendedTiles openTiles={openTiles} setOpenTiles={setOpenTiles} activeWorksheet={activeWorksheet} />
      <OpenTiles
        openTiles={openTiles}
        setOpenTiles={setOpenTiles}
        activeWorksheet={activeWorksheet}
        addColors={addColors}
        removeColors={removeColors}
      />
      <ButtonsBar userName={user?.given_name || "Guest"} userEmail={user?.email || "N/A"} />
    </div>
  );
}
