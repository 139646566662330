/* eslint-disable no-undef */
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutIcon from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";

const LogoutButton = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    localStorage.clear();

    if (Office.context.platform === Office.PlatformType.PC || Office.context.platform === Office.PlatformType.Mac) {
      const logoutPopupUrl = window.location.origin + "/logout-popup.html";

      Office.context.ui.displayDialogAsync(
        logoutPopupUrl,
        { height: 60, width: 30, displayInIframe: false },
        (result) => {
          if (result.status === Office.AsyncResultStatus.Succeeded) {
            const dialog = result.value;

            dialog.addEventHandler(Office.EventType.DialogMessageReceived, (args) => {
              // Close the dialog
              dialog.close();

              if ("message" in args) {
                if (args.message === "logout_success" || args.message === "logout_error") {
                  // Force a re-render of the main app
                  window.location.reload();
                } else {
                  console.error("Unexpected message:", args.message);
                }
              } else {
                console.error("Dialog error:", args.error);
              }
            });
          } else {
            console.error("Failed to open dialog:", result.error);
          }
        }
      );
    } else {
      // For web Excel, use the normal redirect approach
      logout({
        logoutParams: {
          returnTo: window.location.origin + "/index.html",
        },
      });
    }
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "2px", cursor: "pointer" }}
      onClick={handleLogout}
    >
      <Tooltip title="Logout">
        <LogoutIcon fontSize="small" color="action" />
      </Tooltip>
    </div>
  );
};

export default LogoutButton;
