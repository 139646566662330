/* eslint-disable react/jsx-key */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import * as React from "react";
import { useContext } from "react";

import { makeStyles } from "@fluentui/react-components";

import { DebugTab } from "./tiles/debugger/DebugTab";
import { VisualizersPanel } from "./tiles/visualizer/VisualizersPanel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import PullHistoricals from "./tiles/pull_historicals/PullHistoricals";
import { HowIsItCalculated } from "./tiles/how_is_it_calculated/HowIsItCalculated";
import { AddForecasts } from "./tiles/add_forecasts/AddForecasts";
import { DCF } from "./tiles/dcf/dcf";
import {
  getSpreadsheetContext,
  getTiles,
  runTileAction,
  sendWholeSpreadsheetToServer,
  updateServerOnSpreadsheetChange,
  updateServerOnTileClosure,
  updateServerOnTileSelection,
  updateServerOnWorksheetAddition,
  updateServerOnWorksheetDeletion,
} from "./utils/serverUtils";
import {
  addWorkbookEventListener,
  addWorksheetAddedEventListener,
  addWorksheetDeletedEventListener,
  addWorksheetEventListener,
  getWorkbookData,
  insertData,
} from "./utils/excelUtils";
import { ContextParagraph } from "./ContextParagraph";
import SearchBar from "./reusedComponents/SearchBar";
import { Alerting } from "./tiles/alerting/Alerting";
import { UploadFromFileTile } from "./tiles/model_upload/UploadFromFileTile";
import { ChangesLogContext } from "./App";
import { TileStatus } from "./types/TileStatus";

const useStyles = makeStyles({
  recommended_tile: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "6px",
    paddingBottom: "6px",
    cursor: "pointer",
    backgroundColor: "#ebf8ff",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "12px",
    border: "1px solid #42BDFF",
    fontSize: "13px",
    "&:hover": {
      backgroundColor: "#bfeaff",
    },
  },
  recommendations: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "8px",
    marginTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
  },
  selected_tiles: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "10px",
    marginTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
  },
  tile_wrapper: {
    borderRadius: "10px",
    paddingBottom: "8px",
    backgroundColor: "#ebf8ff",
    border: "1px solid rgb(121, 208, 255)",
    marginLeft: "4px",
    marginRight: "4px",
  },
  tile_header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#42BDFF",
    color: "white",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    "& h5": {
      fontSize: "16px",
      margin: "6px",
      padding: "0px",
    },
  },
  tile_logic: {
    padding: "4px",
  },
  fadeIn: {
    opacity: 0,
    transition: "opacity 1s ease-in",
    "&.visible": {
      opacity: 1,
    },
  },
});

function RecommendedTile({ tileID, name, selectTile }) {
  const styles = useStyles();
  return (
    <div className={styles.recommended_tile} onClick={() => selectTile(tileID)}>
      <AddCircleIcon fontSize="small" sx={{ color: "#42BDFF" }} />
      {name}
    </div>
  );
}

function RecommendedTiles({ tiles, selectTile }) {
  const styles = useStyles();
  return (
    <div className={styles.recommendations}>
      {tiles
        ?.filter((tile) => tile["status"] == TileStatus.RECOMMENDED)
        ?.filter((tile, index, self) => index === self.findIndex((t) => t["name"] === tile["name"]))
        ?.map((tile) => (
          <RecommendedTile
            key={tile["id"]}
            tileID={tile["id"]}
            name={tile["name"]}
            // tileType={tile["tile_type"]} # TODO: add logo to recommended tile
            selectTile={selectTile}
          />
        ))}
    </div>
  );
}

function TileHeader({ tile, closeTile }) {
  const styles = useStyles();
  const dataTileLogo = require("../../../assets/data-tile-logo.png");
  const buildTileLogo = require("../../../assets/build-tile-logo.png");

  return (
    <div className={styles.tile_header}>
      {tile["tile_type"] === "DATA" && (
        <div style={{ marginLeft: "4px" }}>
          <img style={{ width: "24px", height: "30px", color: "white" }} src={dataTileLogo} />
        </div>
      )}
      {tile["tile_type"] === "ANALYTICS" && (
        <div style={{ marginLeft: "4px" }}>
          <img style={{ width: "24px", height: "30px", color: "white" }} src={buildTileLogo} />
        </div>
      )}
      <h5>{tile["name"]}</h5>
      <div
        style={{ marginRight: "6px", marginTop: "6px", cursor: "pointer", marginLeft: "auto" }}
        onClick={() => closeTile(tile["id"])}
      >
        <CloseIcon fontSize="medium" />
      </div>
    </div>
  );
}

function createTileComponent(activeWorksheet, addColors, removeColors, tileName, tileId, insertDataHook) {
  const runTileActionHook = async (actionName, actionData) => {
    const result = await runTileAction(tileId, tileName, actionName, actionData);
    return result;
  };

  const tilesMap = {
    Debugger: <DebugTab tileId={tileId} addColors={addColors} removeColors={removeColors} />,
    Visualizers: <VisualizersPanel tileId={tileId} addColors={addColors} removeColors={removeColors} />,
    "Add Forecasts": (
      <AddForecasts
        activeWorksheet={activeWorksheet}
        runTileActionHook={runTileActionHook}
        insertDataHook={insertDataHook}
      />
    ),
    "Pull Historicals": <PullHistoricals runTileActionHook={runTileActionHook} insertDataHook={insertDataHook} />,
    "How Is It Calculated": (
      <HowIsItCalculated
        activeWorksheet={activeWorksheet}
        runTileActionHook={runTileActionHook}
        insertDataHook={insertDataHook}
      />
    ),
    "DCF Valuation": (
      <DCF activeWorksheet={activeWorksheet} runTileActionHook={runTileActionHook} insertDataHook={insertDataHook} />
    ),
    Alerting: <Alerting activeWorksheet={activeWorksheet} runTileActionHook={runTileActionHook} />,
    "Upload From File": <UploadFromFileTile runTileActionHook={runTileActionHook} insertDataHook={insertDataHook} />,
  };

  return tilesMap[tileName];
}

function SelectedTiles({ tiles, activeWorksheet, closeTile, addColors, removeColors, insertDataHook }) {
  const styles = useStyles();
  return (
    <div className={styles.selected_tiles}>
      {tiles
        ?.filter((tile) => tile["status"] == TileStatus.SELECTED)
        ?.filter((tile, index, self) => index === self.findIndex((t) => t["name"] === tile["name"]))
        ?.map((tile) => (
          <div key={tile["id"]} className={styles.tile_wrapper}>
            <TileHeader tile={tile} closeTile={closeTile} />
            <div className={styles.tile_logic}>
              {createTileComponent(activeWorksheet, addColors, removeColors, tile["name"], tile["id"], insertDataHook)}
            </div>
          </div>
        ))}
    </div>
  );
}

export default function Tiles({ activeWorksheet, addColors, removeColors, tiles, selectTile, closeTile, updateTiles }) {
  const [spreadsheetContext, setSpreadsheetContext] = React.useState({
    is_empty: true,
    historical_columns: [],
    forecast_columns: [],
  });
  const [isContextParagraphCompleted, setIsContextParagraphCompleted] = React.useState(false);
  const { addChange } = useContext(ChangesLogContext);

  React.useEffect(() => {
    updateTiles().then(() => {
      getSpreadsheetContext().then((context) => {
        setSpreadsheetContext(context);
      });
    });
    addWorkbookEventListener((event: Excel.WorksheetChangedEventArgs) => {
      updateServerOnSpreadsheetChange(event).then((res) => {
        if (res) {
          updateTiles().then(() => {
            getSpreadsheetContext().then((context) => {
              setSpreadsheetContext(context);
            });
          });
        }
      });
    });
    addWorksheetAddedEventListener(async (event: Excel.WorksheetAddedEventArgs) => {
      await updateServerOnWorksheetAddition(event);
      addWorksheetEventListener(event.worksheetId, (event: Excel.WorksheetChangedEventArgs) => {
        updateServerOnSpreadsheetChange(event).then((res) => {
          if (res) {
            updateTiles().then(() => {
              getSpreadsheetContext().then((context) => {
                setSpreadsheetContext(context);
              });
            });
          }
        });
      });
    });
    addWorksheetDeletedEventListener(async (event: Excel.WorksheetDeletedEventArgs) => {
      await updateServerOnWorksheetDeletion(event);
      updateTiles().then(() => {
        getSpreadsheetContext().then((context) => {
          setSpreadsheetContext(context);
        });
      });
    });
  }, []);

  const insertDataHook = async (data, tileName, description) => {
    const originalData = await getWorkbookData();
    await insertData(data);
    addChange(originalData, data, tileName, description);
    sendWholeSpreadsheetToServer().then(async () => {
      const context = await getSpreadsheetContext();
      setSpreadsheetContext(context);
      updateTiles();
    });
  };

  const styles = useStyles();

  return (
    <div>
      <ContextParagraph
        spreadsheetContext={spreadsheetContext}
        setIsContextParagraphCompleted={setIsContextParagraphCompleted}
      />
      <div className={`${styles.fadeIn} ${isContextParagraphCompleted ? "visible" : ""}`}>
        <RecommendedTiles tiles={tiles} selectTile={selectTile} />
        <SelectedTiles
          tiles={tiles}
          activeWorksheet={activeWorksheet}
          closeTile={closeTile}
          addColors={addColors}
          removeColors={removeColors}
          insertDataHook={insertDataHook}
        />
        <SearchBar placeholder="Search Other Tiles..." />
      </div>
    </div>
  );
}
