/* eslint-disable no-undef */
import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import { Auth0Provider } from "@auth0/auth0-react";

const rootElement: HTMLElement | null = document.getElementById("container");
const root = rootElement ? createRoot(rootElement) : undefined;

/* Render application after Office initializes */
Office.onReady(() => {
  root?.render(
    <Auth0Provider
      domain="auth.wizzo.tech"
      clientId="9mxbBlDI313fGbUpScEeuTKs9xgZSQlR"
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      cacheLocation="localstorage"
    >
      <App />
    </Auth0Provider>
  );
});
