import * as React from "react";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";

export default function ItemOpenCloseIcon({ isOpen, setIsOpen }) {
  return (
    <IconButton
      size="small"
      onClick={async () => {
        setIsOpen(!isOpen);
      }}
    >
      {isOpen ? (
        <Tooltip title="Close">
          <ExpandCircleDownIcon fontSize="small" sx={{ transform: "rotate(180deg)" }} />
        </Tooltip>
      ) : (
        <Tooltip title="Open">
          <ExpandCircleDownIcon fontSize="small" />
        </Tooltip>
      )}
    </IconButton>
  );
}
