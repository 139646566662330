import * as React from "react";

import { useCommonStyles } from "../../../reusedComponents/commonStyles";
import ItemOpenCloseIcon from "./utils/ItemOpenCloseIcon";
import SeverityIcon from "./utils/SeverityIcon";
import IssueActionButton from "./utils/IssueActionButton";

export default function IssueHeader({ issue, isOpen, setIsOpen, dismissIssue, undo }) {
  const commonStyles = useCommonStyles();
  return (
    <div className={commonStyles.item__oneliner}>
      <ItemOpenCloseIcon isOpen={isOpen} setIsOpen={setIsOpen} />
      <SeverityIcon issue={issue} />
      <h4>{issue.cellsRange}</h4>
      <p>{issue.title}</p>
      <IssueActionButton issue={issue} dismissIssue={dismissIssue} undo={undo} />
    </div>
  );
}
