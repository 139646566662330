import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { TabPanel } from "@mui/lab";
import DebugTab from "./debugging/DebugTab";
import { makeStyles } from "@fluentui/react-components";
import VersionsTab from "./versions/VersionsTab";
import ToolsTab from "./tools/ToolsTab";
import DiamondIcon from '@mui/icons-material/Diamond';
import FeedbackPopup from "./FeedbackPopup";
import WizzoFooterButton from "./utils/WizzoButton";
import LogoutButton from "./utils/LogoutButton";
import AddCommentIcon from "@mui/icons-material/AddComment";

const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    minHeight: "85vh",
  },
  buttons_bar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: "10px",
    marginTop: "auto",
    paddingTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "80%",
  },
});

const ButtonsBar = ({ userName, userEmail }) => {
  const styles = useStyles();
  const [isFeedbackOpen, setIsFeedbackOpen] = React.useState(false);
  return (
    <div>
      {isFeedbackOpen && (
        <FeedbackPopup setIsFeedbackOpen={setIsFeedbackOpen} userName={userName} userEmail={userEmail} />
      )}
      <div className={styles.buttons_bar}>
        <WizzoFooterButton
          buttonText="Wizzo Pro"
          buttonIcon={<DiamondIcon color="success" />}
          action={() => {
            window.open("https://www.wizzo.tech/", "_blank");
          }}
        />
        <WizzoFooterButton
          buttonText="Feedback"
          buttonIcon={<AddCommentIcon color="success" />}
          action={() => {
            setIsFeedbackOpen(true);
          }}
        />
        <LogoutButton />
      </div>
    </div>
  );
};

function AppTabs() {
  const [value, setValue] = React.useState("1");
  const styles = useStyles();

  // @ts-ignore
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const tabTitleStyle = {
    textTransform: "none",
    fontSize: "15px",
    fontWeight: 600,
  };

  return (
    <div className={styles.content}>
      <div>
        <Box sx={{ width: "100%" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider", textColor: "white" }}>
              <TabList
                onChange={handleChange}
                variant="fullWidth"
                aria-label="full width tabs example"
                textColor="inherit"
                sx={{ backgroundColor: "#F7FBF4", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#4F7434",
                    color: "#4F7434",
                  },
                }}
              >
                <Tab label="Debug" value="1" sx={tabTitleStyle}/>
                <Tab label="Versions" value="2" sx={tabTitleStyle}/>
                <Tab label="Tools" value="3" sx={tabTitleStyle}/>
              </TabList>
            </Box>
            <TabPanel value="1" keepMounted={true} sx={{ margin: "0%", padding: "0%" }}>
              <DebugTab />
            </TabPanel>
            <TabPanel value="2" keepMounted={true} sx={{ margin: "0%", padding: "0%" }}>
              {/* <VersionsTabDemo /> */}
              <VersionsTab />
            </TabPanel>
            <TabPanel value="3" keepMounted={true} sx={{ margin: "0%", padding: "0%" }}>
              {/* <ToolsTabDemo /> */}
              <ToolsTab />
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  );
}

export default function AppContent({ user }) {
  const styles = useStyles();
  console.log("user", user);
  return (
    <div className={styles.content}>
      <AppTabs />
      <ButtonsBar userName={user?.given_name || "Guest"} userEmail={user?.email || "N/A"} />
    </div>
  );
}
