import * as React from "react";
import { Counter } from "./utils/Counter";
import { useCommonStyles } from "../../../utils/commonStyles";
import { ListOpenCloseIcon } from "./utils/ListOpenCloseIcon";
import RefreshButton from "../../../utils/RefreshButton";
import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  refersh__button: {
    marginLeft: "auto",
    cursor: "pointer",
  },
});

type ListHeaderProps = {
  listName: string;
  list?: any[];
  isOpen?: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  onRefresh?: () => Promise<void>;
};

export default function HierarchicalListHeader(props: ListHeaderProps) {
  const styles = useCommonStyles();
  const localStyles = useStyles();
  return (
    <div className={styles.list__header}>
      <h4>{props.listName}</h4>
      {props.list && <Counter list={props.list} />}
      {props.setIsOpen && (
        <div style={{ marginLeft: "auto" }}>
          <ListOpenCloseIcon isOpen={props.isOpen} setIsOpen={props.setIsOpen} />
        </div>
      )}
      {props.onRefresh && (
        <div className={localStyles.refersh__button}>
          <RefreshButton onRefresh={props.onRefresh} />
        </div>
      )}
    </div>
  );
}
