import * as React from "react";
import { makeStyles } from "@fluentui/react-components";
import { insertData } from "../utils/excelUtils";
import SearchBar, { statements } from "./SearchBar";

const useStyles = makeStyles({
  hist_importer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginTop: "10px",
    marginLeft: "10px",
  },
});

export default function HistoricalsPuller({ activeWorksheet }) {
  const styles = useStyles();

  const loadHistoricals = async () => {
    let data = require("./appleHistoricals.json");
    insertData(data["ag-grid"], activeWorksheet);
  };

  return (
    <div className={styles.hist_importer}>
      <SearchBar />
      <SearchBar options={statements} label={"Choose Statements"} />
      <div>
        <p style={{ marginBottom: "2px", paddingBottom: "4px" }}>Choose Data Frequency:</p>
        <div style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "center" }}>
          <label>
            <input type="radio" name="periods" value="q" defaultChecked={true} />
            Quarterly
          </label>
          <label>
            <input type="radio" name="periods" value="a" />
            Annual
          </label>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignContent: "center" }}>
        <p style={{ margin: "4px" }}>Available Sources:</p>
        <select name="selectedDataSource" style={{ height: "30px" }}>
          <option value="sec">SEC Filings</option>
          <option value="capiq">Capital IQ</option>
          <option value="va">Visible Alpha</option>
          <option value="fastset">FactSet</option>
          <option value="bloomberg">Bloomberg</option>
        </select>
      </div>
      <button
        style={{
          color: "white",
          backgroundColor: "#004367",
          borderRadius: "25px",
          border: "0px",
          width: "25%",
          height: "30px",
          marginLeft: "auto",
          marginRight: "auto",
          cursor: "pointer",
        }}
        onClick={loadHistoricals}
      >
        Pull Data
      </button>
    </div>
  );
}
