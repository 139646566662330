/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import * as React from "react";

import { makeStyles } from "@fluentui/react-components";
import DiamondIcon from "@mui/icons-material/Diamond";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AddCommentIcon from "@mui/icons-material/AddComment";

import FeedbackPopup from "./FeedbackPopup";
import WizzoFooterButton from "./reusedComponents/WizzoButton";
import LogoutButton from "./reusedComponents/LogoutButton";
import { addWorksheetChangeEventListeners, getActiveWorksheetName } from "./utils/excelUtils";
import { addColorsLayer, removeColorsLayer } from "./tiles/debugger/utils";
import Tiles from "./Tiles";
import {
  getStartupData,
  getTiles,
  startSession,
  updateServerOnTileClosure,
  updateServerOnTileSelection,
} from "./utils/serverUtils";
import { insertOrReplaceTabs } from "./utils/excelUtils";
import { Review } from "./Review";
import Export from "./Export";
import { ChangesLogContext } from "./App";
import { TileStatus } from "./types/TileStatus";

const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    minHeight: "85vh",
  },
  buttons_bar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: "10px",
    marginTop: "auto",
    paddingTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "80%",
  },
  tabs: {
    minHeight: "20px",
    "& .MuiTabs-indicator": {
      backgroundColor: "#42BDFF",
    },
    borderRadius: "6px 6px 0 0",
    borderBottom: "1px solid #42BDFF",
    width: "fit-content",
    marginLeft: "auto",
    marginRight: "auto",
  },
  tab: {
    textTransform: "none",
    fontWeight: "600",
    minHeight: "20px",
    padding: "4px 16px",
    color: "#42BDFF",
    "&.Mui-selected": {
      color: "white",
      backgroundColor: "#42BDFF",
    },
  },
});

const ButtonsBar = ({ userName, userEmail }) => {
  const styles = useStyles();
  const [isFeedbackOpen, setIsFeedbackOpen] = React.useState(false);
  return (
    <div style={{ marginTop: "auto" }}>
      {isFeedbackOpen && (
        <FeedbackPopup setIsFeedbackOpen={setIsFeedbackOpen} userName={userName} userEmail={userEmail} />
      )}
      <div className={styles.buttons_bar}>
        <WizzoFooterButton
          buttonText="Wizzo Pro"
          buttonIcon={<DiamondIcon color="inherit" />}
          action={() => {
            window.open(
              "https://app.visily.ai/projects/9bb26e2d-e5c3-404d-a080-16e2ff3f767a/boards/1438605/presenter?play-mode=Prototype",
              "_blank"
            );
          }}
        />
        <WizzoFooterButton
          buttonText="Feedback"
          buttonIcon={<AddCommentIcon color="inherit" />}
          action={() => {
            setIsFeedbackOpen(true);
          }}
        />
        <LogoutButton />
      </div>
    </div>
  );
};

export default function AppContent({ user }) {
  const styles = useStyles();
  const [tiles, setTiles] = React.useState([]);
  const tilesRef = React.useRef([]);
  const [wasInitialized, setWasInitialized] = React.useState(false);
  const [activeWorksheet, setActiveWorksheet] = React.useState("");
  const [bckColorsMemory, setBckColorsMemory] = React.useState({});
  const [changesLog, setChangesLog] = React.useState([]);
  const bckColorsMemoryRef = React.useRef(bckColorsMemory);

  React.useEffect(() => {
    tilesRef.current = tiles;
  }, [tiles]);

  const updateActiveWorksheet = () => {
    getActiveWorksheetName().then((activeWorksheetName) => {
      setActiveWorksheet(activeWorksheetName);
    });
  };

  /** We let the server decide which tiles are recommended. Not which ones are selected or closed. */
  const updateTiles = async () => {
    getTiles().then((tilesFromServer) => {
      const selectedTiles = tiles.filter((tile) => tile["status"] == TileStatus.SELECTED);
      const recommendedTiles = tilesFromServer
        .filter((tile) => tile["status"] == TileStatus.RECOMMENDED)
        .filter((tile) => !selectedTiles.includes(tile));
      const existingTiles = tilesRef.current.filter((tile) => tile["status"] != TileStatus.RECOMMENDED);
      const newTiles = [...existingTiles, ...recommendedTiles];
      setTiles(newTiles);
    });
  };

  const selectTile = (tileID) => {
    const newTiles = tilesRef.current.map((tile) =>
      tile["id"] === tileID ? { ...tile, status: TileStatus.SELECTED } : tile
    );
    setTiles(newTiles);
    updateServerOnTileSelection(tileID);
  };

  const closeTile = (tileID) => {
    const tileToClose = tilesRef.current.find((tile) => tile.id === tileID);
    const existingRecommendedTile = tilesRef.current.find(
      (tile) => tile.status === TileStatus.RECOMMENDED && tile.name === tileToClose?.name
    );

    // Only update tiles if there isn't already a recommended tile with the same name
    if (!existingRecommendedTile) {
      const newTiles = tilesRef.current.map((tile) =>
        tile.id === tileID ? { ...tile, status: TileStatus.RECOMMENDED } : tile
      );
      setTiles(newTiles);
    } else {
      const newTiles = tilesRef.current.map((tile) =>
        tile.id === tileID ? { ...tile, status: TileStatus.CLOSED } : tile
      );
      setTiles(newTiles);
    }

    updateServerOnTileClosure(tileID);
  };

  const closeSelectedTiles = () => {
    const selectedTiles = tiles.filter((tile) => tile["status"] == TileStatus.SELECTED);
    selectedTiles.forEach((tile) => {
      closeTile(tile.id);
    });
  };

  const addColors = (newColors) => setBckColorsMemory(addColorsLayer(bckColorsMemoryRef.current, newColors));
  const removeColors = (cells, colorToRemove) => {
    const [updatedState, newTopLayer] = removeColorsLayer(bckColorsMemoryRef.current, cells, colorToRemove);
    setBckColorsMemory(updatedState);
    return newTopLayer;
  };

  const initializeAddin = async () => {
    console.log("Starting initializeAddin...");
    try {
      // Always start a new session
      console.log("Starting new session...");
      const newSessionId = await startSession(user);
      if (!newSessionId) {
        console.error("Failed to create session");
        return;
      }

      localStorage.setItem("sessionId", newSessionId);
      localStorage.setItem("username", user.email);
      localStorage.setItem("firstName", user.given_name || "");
      localStorage.setItem("lastName", user.family_name || "");

      // Now that we have a fresh session, get startup data
      const result = await getStartupData();
      console.log("Startup request result:", result);

      setWasInitialized(true);
    } catch (error) {
      console.error("Error in initializeAddin:", error);
    }
    console.log("Completed initializeAddin");
  };

  const addChange = (originalData, data, tileName, description) => {
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");
    const change = {
      timestamp: new Date(),
      description: description,
      tileName: tileName,
      userName: `${firstName} ${lastName}`,
      originalData: () => insertOrReplaceTabs(originalData, data),
    };
    setChangesLog([...changesLog, change]);
  };

  const removeChange = async () => {
    const changeToRemove = changesLog[changesLog.length - 1];
    await changeToRemove.originalData();
    setChangesLog(changesLog.slice(0, -1));
    closeSelectedTiles();
  };

  React.useEffect(() => {
    bckColorsMemoryRef.current = bckColorsMemory;
  }, [bckColorsMemory]);

  React.useEffect(() => {
    console.log("starting AppContent...");
    updateActiveWorksheet();
    addWorksheetChangeEventListeners(updateActiveWorksheet);
    initializeAddin();
  }, []);

  // Prevent Tiles from rendering until we have a valid session
  const [sessionReady, setSessionReady] = React.useState(false);
  React.useEffect(() => {
    const checkSession = () => {
      const sessionId = localStorage.getItem("sessionId");
      setSessionReady(!!sessionId);
    };
    checkSession();
  }, [wasInitialized]);

  const [value, setValue] = React.useState("build");

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <ChangesLogContext.Provider value={{ changesLog, addChange, removeChange }}>
      <div className={styles.content}>
        {wasInitialized && sessionReady && (
          <Tabs value={value} onChange={handleChange} centered className={styles.tabs}>
            <Tab value="build" label="Build" className={styles.tab} />
            <Tab value="review" label="Review" className={styles.tab} />
            <Tab value="export" label="Export" className={styles.tab} />
          </Tabs>
        )}
        {wasInitialized && sessionReady && (
          <div style={{ display: value === "build" ? "block" : "none" }}>
            <Tiles
              activeWorksheet={activeWorksheet}
              addColors={addColors}
              removeColors={removeColors}
              tiles={tiles}
              selectTile={selectTile}
              closeTile={closeTile}
              updateTiles={updateTiles}
            />
          </div>
        )}
        {wasInitialized && sessionReady && (
          <div style={{ display: value === "review" ? "block" : "none" }}>
            <Review />
          </div>
        )}
        {wasInitialized && sessionReady && (
          <div style={{ display: value === "export" ? "block" : "none" }}>
            <Export />
          </div>
        )}
        <ButtonsBar userName={user?.given_name || "Guest"} userEmail={user?.email || "N/A"} />
      </div>
    </ChangesLogContext.Provider>
  );
}
