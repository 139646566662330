/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import * as React from "react";
import { useContext } from "react";
import { makeStyles } from "@fluentui/react-components";
import Button from "@mui/material/Button";
import { Change } from "./types/Change";
import { CircularProgress } from "@mui/material";
import { ChangesLogContext } from "./App";

const useStyles = makeStyles({
  title: {
    fontSize: "16px",
    fontWeight: "500",
    marginBottom: "24px",
    color: "#00649a",
    padding: "12px",
    borderBottom: "1px solid #e9ecef",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    "& svg": {
      color: "#42BDFF",
      fontSize: "16px",
    },
  },
  changesList: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginBottom: "24px",
  },
  changeItem: {
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "2px",
    paddingBottom: "4px",
    backgroundColor: "white",
    borderRadius: "6px",
    fontSize: "14px",
    color: "#2d3436",
    border: "1px solid #e9ecef",
    "&:hover": {
      backgroundColor: "#e9ecef",
    },
  },
  changeHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "12px",
    color: "#6c757d",
  },
  changeDescription: {
    fontSize: "14px",
    color: "#2d3436",
  },
  tileBadgeContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "4px",
  },
  tileBadge: {
    backgroundColor: "#42BDFF",
    color: "white",
    padding: "2px 6px",
    borderRadius: "4px",
    fontSize: "11px",
    margin: "0px",
  },
  buttonContainer: {
    display: "flex",
    gap: "12px",
    justifyContent: "center",
  },
  publishButton: {
    textTransform: "none",
    backgroundColor: "#42BDFF",
    color: "white",
    "&:hover": {
      backgroundColor: "#3aa8e6",
    },
  },
  undoButton: {
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#000000",
      color: "white",
    },
  },
  noChanges: {
    fontSize: "14px",
    color: "#2d3436",
    paddingLeft: "12px",
  },
});

function ChangeItem({ change, index }: { change: Change; index: number }) {
  const styles = useStyles();

  const formatDate = (date: Date) => {
    return date.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <div key={index} className={styles.changeItem}>
      <div className={styles.changeHeader}>
        <span>{formatDate(change.timestamp)}</span>
        <div className={styles.tileBadgeContainer}>
          <p>Tile: </p>
          <span className={styles.tileBadge}>{change.tileName || "Manual"}</span>
        </div>
      </div>
      <div>
        <div className={styles.changeDescription}>
          <b>{change.description}</b>
        </div>
        <span style={{ fontSize: "12px" }}>Made by: {change.userName}</span>
      </div>
    </div>
  );
}

export function Review() {
  const { changesLog, removeChange } = useContext(ChangesLogContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const styles = useStyles();

  const undoChange = async () => {
    setIsLoading(true);
    try {
      await removeChange();
      await new Promise(() => window.setTimeout(() => setIsLoading(false), 3000));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h1 className={styles.title}>
        <span className="material-symbols-outlined">history</span>
        Changes Log
      </h1>
      <div className={styles.changesList}>
        {changesLog?.length === 0 && <div className={styles.noChanges}>No changes made</div>}
        {changesLog?.map((change: Change, index) => <ChangeItem key={index} change={change} index={index} />)}
      </div>
      <div className={styles.buttonContainer}>
        <Button
          variant="contained"
          className={styles.publishButton}
          onClick={() => {
            /* TODO: Implement publish */
          }}
        >
          Publish
        </Button>
        <Button variant="outlined" className={styles.undoButton} disabled={isLoading} onClick={undoChange}>
          {isLoading ? <CircularProgress size={20} color="inherit" /> : "Undo"}
        </Button>
      </div>
    </div>
  );
}
