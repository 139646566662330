/* eslint-disable no-undef */
import React, { useEffect } from "react";
import { makeStyles } from "@fluentui/react-components";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "./reusedComponents/LoginButton";
import AppContent from "./AppContent";

export const ChangesLogContext = React.createContext(null);

const useStyles = makeStyles({
  logo: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "20px",
    width: "50%",
    height: "20%",
  },
  addin__main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    flex: "1 0 0",
    padding: "4px",
    minHeight: "90vh",
  },
  app_content: {
    minHeight: "80vh",
  },
});

export default function App() {
  const styles = useStyles();
  const { user, isAuthenticated, isLoading } = useAuth0();
  const mainLogo = require("../../../assets/wizzo-logo.png");

  useEffect(() => {
    const initiateSession = async () => {
      if (isAuthenticated && user) {
        localStorage.setItem("auth_token", user.token);
        localStorage.setItem("firstName", user.given_name || "");
        localStorage.setItem("lastName", user.family_name || "");
        // Don't create session here - let AppContent handle that
      }
    };

    initiateSession();
  }, [isAuthenticated]);

  return (
    <div className={`${styles.addin__main}`}>
      <img className={styles.logo} src={mainLogo} />
      {isLoading ? (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <p>Wizzo is Logging you in...</p>
          <CircularProgress color="success" />
        </div>
      ) : (
        <div className={styles.app_content}>
          {!isAuthenticated ? (
            <div>
              <LoginButton />
            </div>
          ) : (
            <div className={styles.app_content}>
              <AppContent user={user} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
