import React from "react";

const SWITCH_WIDTH_PX = 38;
const HANDLE_DIAMETER_PX = 16;
const SWITCH_OFFSET_PX = 3;
const Switcher = ({ containerCheckedColor = "#8dae9c", containerUncheckedColor = "#ccc", value, onClick }) => {
  return (
    <div
      style={{
        width: SWITCH_WIDTH_PX,
        height: HANDLE_DIAMETER_PX + 2 * SWITCH_OFFSET_PX,
        borderRadius: HANDLE_DIAMETER_PX,
        border: "1px #ddd solid",
        position: "relative",
        marginLeft: "8px",
        transition: ".3s",
        cursor: "pointer",
        background: value ? containerCheckedColor : containerUncheckedColor,
      }}
      onClick={onClick}
    >
      <div
        style={{
          background: "white",
          borderRadius: "100%",
          height: HANDLE_DIAMETER_PX,
          width: HANDLE_DIAMETER_PX,
          position: "absolute",
          top: SWITCH_OFFSET_PX,
          left: value ? SWITCH_WIDTH_PX - HANDLE_DIAMETER_PX - SWITCH_OFFSET_PX : SWITCH_OFFSET_PX,
          transition: ".3s",
        }}
      ></div>
      <input
        type="checkbox"
        value={value}
        onChange={(e) => {
          onClick(e.target.value);
        }}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default Switcher;
