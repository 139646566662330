const currentPath = process.cwd();

console.log("Loading environment variables from:", currentPath);
console.log("Parsed Environment Variables:", JSON.stringify(process.env));
let serverURL = process.env.REACT_APP_FLASK_SERVER_URL;
console.log("Server URL:", serverURL);

if (!serverURL) {
  serverURL = "https://wizzo-addin-flask-dcd9dsgcayg8gubd.eastus2-01.azurewebsites.net";
}

/**
 *
 * @param selectedRangeJSON - JSON string containing the data in the workbook
 * @returns list of issues found in the workbook represented as JSON objects
 */
export async function getHighlightsFromServer(selectedRangeJSON: string) {
  const highlightCells = await fetchDataFromServer("/get_highlights", selectedRangeJSON);
  return highlightCells;
}

export async function getIssuesFromServer(selectedRangeJSON: string) {
  const issuesList = await fetchDataFromServer("/search", selectedRangeJSON, (data) => {
    for (let issue of data) {
      issue.status = "OPEN";
    }
    return data;
  });

  return issuesList;
}

function prepareRequestData(selectedRangeJSON: string): string | null {
  const sessionId = localStorage.getItem("sessionId");
  const username = localStorage.getItem("username");

  if (!sessionId || !username) {
    console.error("Session ID or username is missing.");
    return null;
  }

  let requestData;
  try {
    requestData = JSON.parse(selectedRangeJSON);
  } catch (error) {
    console.error("Failed to parse selectedRangeJSON:", error);
    return null;
  }

  requestData.sessionId = sessionId;
  requestData.username = username;

  const sessionSelectedRangeJSON = JSON.stringify(requestData);
  return sessionSelectedRangeJSON;
}

export async function fetchDataFromServer(
  endpoint: string,
  selectedRangeJSON: string,
  postProcess: (data: any) => any = (data) => data
): Promise<any> {
  const sessionSelectedRangeJSON = prepareRequestData(selectedRangeJSON);
  if (!sessionSelectedRangeJSON) {
    return [];
  }

  const response = await fetch(serverURL + endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: sessionSelectedRangeJSON,
  });

  if (!response.ok) {
    console.error(`Failed to fetch data from the server. Endpoint: ${endpoint}`);
    return [];
  }

  const data = await response.json();
  return postProcess(data);
}

export async function startSession(user) {
  try {
    const response = await fetch(serverURL + "/start_session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
    if (!response.ok) {
      console.error("Failed to start session:", response.statusText);
      return null;
    }
    const data = await response.json();
    return data.session_id;
  } catch (error) {
    console.error("Error starting session:", error);
    return null;
  }
}

export async function logAction(actionType, description = "", additionalData = "", relatedEntity = "") {
  try {
    const username = localStorage.getItem("username");
    const sessionId = localStorage.getItem("sessionId");
    const requestData = {
      username: username,
      sessionId: sessionId,
      actionType: actionType,
      description: description,
      additionalData: additionalData,
      relatedEntity: relatedEntity,
    };
    const response = await fetch(serverURL + "/log_action", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });
    if (!response.ok) {
      console.error("Failed to log action:", response.statusText);
      return null;
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error logging action:", error);
    return null;
  }
}
