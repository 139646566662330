/* eslint-disable no-undef */
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { dismissIssue, markResolved, undoDismissalOrResolution } from "./utils";
import { getIssues } from "./debuggerUtils";
import { getActiveWorksheetName } from "../../utils/excelUtils";

import CircularProgress from "@mui/material/CircularProgress";
import ResovledList from "./lists/ResolvedList";
import DismissedList from "./lists/DismissedList";
import OpenIssuesList from "./lists/OpenIssuesList";
import IssueObject from "./interfaces/IssueObject";

function WaitingForIssues() {
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <p>Wizzo is looking for issues</p>
      <CircularProgress color="success" />
    </div>
  );
}

interface DebugTabProps {
  tileId: string;
  addColors: (cells: string[], color: string) => void;
  removeColors: (cells: string[]) => void;
}

export const DebugTab: React.FC<DebugTabProps> = ({ tileId, addColors, removeColors }) => {
  const [activeWorksheet, setActiveWorksheet] = useState("");
  const [wasLoaded, setWasLoaded] = useState(false);
  const [issuesList, setIssuesList] = useState([]);
  const issuesListRef = useRef(issuesList);

  const updateIssues = async () => {
    const worksheet = await getActiveWorksheetName();
    setActiveWorksheet(worksheet);
    const newIssues = await getIssues(tileId);
    const issuesToKeep: IssueObject[] = issuesListRef.current.filter(
      (issue) => issue.status === "DISMISSED" || issue.status === "RESOLVED"
    );
    const updatedIssues: IssueObject[] = newIssues["data"].concat(issuesToKeep);
    setWasLoaded(true);
    setIssuesList(updatedIssues);
  };

  const dismiss = (issueId: string) => setIssuesList(dismissIssue(issuesListRef.current, issueId));
  const resolve = (issueId: string, originalFormulas: {}) =>
    setIssuesList(markResolved(issuesListRef.current, issueId, originalFormulas));
  const undo = (issueId: string) => setIssuesList(undoDismissalOrResolution(issuesListRef.current, issueId));

  useEffect(() => {
    issuesListRef.current = issuesList;
  }, [issuesList]);

  useEffect(() => {
    updateIssues();
  }, []);

  if (wasLoaded) {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <OpenIssuesList
          issues={issuesList}
          activeWorksheet={activeWorksheet}
          dismissIssue={dismiss}
          markResolved={resolve}
          undo={undo}
          addColorsLayer={addColors}
          removeColorsLayer={removeColors}
          updateIssues={updateIssues}
        />
        <ResovledList
          issues={issuesList}
          activeWorksheet={activeWorksheet}
          dismissIssue={dismiss}
          markResolved={resolve}
          undo={undo}
        />
        <DismissedList
          issues={issuesList}
          activeWorksheet={activeWorksheet}
          dismissIssue={dismiss}
          markResolved={resolve}
          undo={undo}
        />
      </div>
    );
  } else {
    return <WaitingForIssues />;
  }
};
