/* eslint-disable office-addins/load-object-before-read */
/* eslint-disable office-addins/no-context-sync-in-loop */
/* eslint-disable no-undef */

/**
 *
 * @param range - Excel JS Range object
 * @returns Extract the address of the used range, extend it to include the top left corner and return it
 */
function getExtendedRange(range: Excel.Range): string {
  let usedRangeAddress = range.address;
  let usedRangeAddressArray = usedRangeAddress.split("!");
  let usedRangeStart = usedRangeAddressArray[1].split(":")[0];
  usedRangeAddress = usedRangeAddress.replace(usedRangeStart, "A1");
  return usedRangeAddress;
}

/**
 *
 * @param worksheetName - name of the active worksheet
 * @returns the address of the extended used range (including the top left corner) in the active worksheet
 */
async function getExtendedRangeFromWorksheetName(worksheetName: string) {
  return await Excel.run({ delayForCellEdit: true }, async (context) => {
    const activeWorksheet = context.workbook.worksheets.getItem(worksheetName);
    const usedRange = activeWorksheet.getUsedRange();
    usedRange.load("address");
    await context.sync();
    const extendedRangeAddress = getExtendedRange(usedRange);
    return extendedRangeAddress;
  });
}

export function getActiveWorksheetName() {
  return Excel.run({ delayForCellEdit: true }, async (context) => {
    let activeWorksheet = context.workbook.worksheets.getActiveWorksheet();
    activeWorksheet.load("name");
    await context.sync();
    return activeWorksheet.name;
  });
}

export async function getRangeData(context, range: Excel.Range) {
  range.load("formulas, numberFormat, values");
  const cellProps = range.getCellProperties({
    address: true,
    format: {
      fill: { color: true },
      font: {
        bold: true,
        italic: true,
        color: true,
        name: true,
        underline: true,
        size: true,
      },
      horizontalAlignment: true,
      verticalAlignment: true,
      indentLevel: true,
    },
  });
  const colProps = range.getColumnProperties({
    format: {
      columnWidth: true,
    },
    columnHidden: true,
  });
  const rowProps = range.getRowProperties({
    format: {
      rowHeight: true,
    },
    rowHidden: true,
  });
  await context.sync();
  return {
    formulas: range.formulas,
    numberFormat: range.numberFormat,
    values: range.values,
    cellProps: cellProps.value,
    colProps: colProps.value,
    rowProps: rowProps.value,
  };
}

export function getWorkbookData() {
  return Excel.run({ delayForCellEdit: true }, async (context) => {
    let worksheets = context.workbook.worksheets;
    let workbookData = {};
    worksheets.load("items");
    await context.sync();
    for (let i = 0; i < worksheets.items.length; i++) {
      let worksheet = worksheets.items[i];
      let range = worksheet.getUsedRange();
      range.load("address");
      await context.sync();
      const extendedRangeAddress = getExtendedRange(range);
      let extendedRange = worksheet.getRange(extendedRangeAddress);
      const worksheetData = await getRangeData(context, extendedRange);
      worksheetData["worksheetId"] = worksheet.id;
      workbookData[worksheet.name] = worksheetData;
    }
    return workbookData;
  });
}

export async function insertOrReplaceTabs(originalData, currentDataAddition) {
  const currentWorksheetNames = Object.keys(currentDataAddition);
  await deleteWorksheets(currentWorksheetNames);
  await insertData(originalData);
}

async function deleteWorksheets(worksheetNames: string[]) {
  return Excel.run({ delayForCellEdit: true }, async (context) => {
    const worksheets = context.workbook.worksheets;
    worksheetNames.forEach((worksheetName) => {
      const worksheet = worksheets.getItemOrNullObject(worksheetName);
      worksheet.delete();
    });
    await context.sync();
  });
}

export async function insertData(data) {
  return await Excel.run({ delayForCellEdit: true }, async (context) => {
    const insertions = Object.keys(data).map((worksheetName) =>
      insertDataToWorksheet(data[worksheetName], worksheetName)
    );
    await Promise.all(insertions);
    await context.sync();
    return true;
  }).catch((error) => {
    console.log("insertData crashed with error:", error);
    return false;
  });
}

async function insertDataToWorksheet(data, worksheetName, startCell = "A1") {
  const endCell = indexToCol(data.values[0].length) + data.values.length;
  try {
    await Excel.run({ delayForCellEdit: true }, async (context) => {
      let worksheet = context.workbook.worksheets.getItemOrNullObject(worksheetName);
      await context.sync();
      if (worksheet.isNullObject) {
        worksheet = context.workbook.worksheets.add(worksheetName);
        await context.sync();
      }
      const range = worksheet.getRange(startCell + ":" + endCell);
      range.setCellProperties(data.cellProps);
      range.setColumnProperties(data.colProps);
      range.setRowProperties(data.rowProps);
      Object.keys(data).forEach(function (key) {
        // If the key is "values" and "formulas" is also present in the data object, do nothing:
        if (key === "values" && "formulas" in data) {
          // Do nothing
        } else if (!(key === "cellProps" || key === "colProps" || key === "rowProps")) {
          range[key] = data[key];
        }
      });
      await context.sync();
    });
    return true;
  } catch (error) {
    console.log("Data Insertion Failed", error);
    return false;
  }
}

export function addWorkbookEventListener(executable) {
  Excel.run({ delayForCellEdit: true }, async (context) => {
    const worksheets = context.workbook.worksheets;
    worksheets.onChanged.add(executable);
    await context.sync();
  });
}

export function addWorksheetEventListener(worksheetId, executable) {
  Excel.run({ delayForCellEdit: true }, async (context) => {
    const worksheet = context.workbook.worksheets.getItem(worksheetId);
    worksheet.onChanged.add(executable);
    await context.sync();
  });
}

export function addWorksheetAddedEventListener(executable) {
  Excel.run({ delayForCellEdit: true }, async (context) => {
    const worksheets = context.workbook.worksheets;
    worksheets.onAdded.add(executable);
    await context.sync();
  });
}

export function addWorksheetDeletedEventListener(executable) {
  Excel.run({ delayForCellEdit: true }, async (context) => {
    const worksheets = context.workbook.worksheets;
    worksheets.onDeleted.add(executable);
    await context.sync();
  });
}

export function addWorksheetChangeEventListeners(executable) {
  Excel.run({ delayForCellEdit: true }, async (context) => {
    const worksheets = context.workbook.worksheets;
    worksheets.onActivated.add(executable);
    await context.sync();
  });
}

export function applyWhenWorksheetDeactivated(worksheetName, executable) {
  Excel.run({ delayForCellEdit: true }, async (context) => {
    const worksheet = context.workbook.worksheets.getItem(worksheetName);
    worksheet.onDeactivated.add(executable);
    await context.sync();
  });
}

export function removeWhenWorksheetDeactivatedEvent(worksheetName, executable) {
  Excel.run({ delayForCellEdit: true }, async (context) => {
    const worksheet = context.workbook.worksheets.getItem(worksheetName);
    worksheet.onDeactivated.remove(executable);
    await context.sync();
  });
}

export function applyWhenWorkbookDeactivated(executable) {
  Excel.run({ delayForCellEdit: true }, async (context) => {
    const workbook = context.workbook;
    workbook.worksheets.onDeactivated.add(executable);
    await context.sync();
  });
}

/**
 * Returns the background color of the given cell
 *
 * @param cellAddress - The cell's address without the worksheet name ("A1" format)
 * @param cellProps - The whole worksheet cell properties object
 * @returns The color of the cell
 */
function getCellColor(cellAddress: string, cellProps: Excel.CellProperties[][]): string {
  const cellXYAddress = cellAddress.split(/(\d+)/);
  const cellRow = parseInt(cellXYAddress[1]) - 1;
  const cellCol = colToIndex(cellXYAddress[0]) - 1;
  const cellColor = cellProps[cellRow][cellCol].format.fill.color;
  return cellColor;
}

export async function getCellsColors(cells: string[], activeWorksheetName: string) {
  let colors = {};
  return await Excel.run({ delayForCellEdit: true }, async (context) => {
    const activeWorksheet = context.workbook.worksheets.getItem(activeWorksheetName);
    const extendedRangeAddress = await getExtendedRangeFromWorksheetName(activeWorksheetName);
    let extendedUsedRange = activeWorksheet.getRange(extendedRangeAddress);
    const cellProps = extendedUsedRange.getCellProperties({ address: true, format: { fill: { color: true } } });
    await context.sync();
    for (let i = 0; i < cells.length; i++) {
      const cellAddress = cells[i].split("!")[1];
      if (cellAddress.includes(":")) {
        const expandedCells = expandRange(cellAddress);
        for (let j = 0; j < expandedCells.length; j++) {
          colors[activeWorksheetName + "!" + expandedCells[j]] = getCellColor(expandedCells[j], cellProps.value);
        }
      } else {
        colors[cells[i]] = getCellColor(cellAddress, cellProps.value);
      }
    }
    return colors;
  }).catch((error) => {
    console.log("getCellsColors crashed with error:", error);
    console.log("inputs were (cells):", cells);
    return colors;
  });
}

// Helper function to convert column letter to index
function colToIndex(col) {
  let index = 0;
  for (let i = 0; i < col.length; i++) {
    index = index * 26 + (col.charCodeAt(i) - "A".charCodeAt(0) + 1);
  }
  return index;
}

// Helper function to convert index to column letter
function indexToCol(index) {
  let col = "";
  while (index > 0) {
    index--;
    col = String.fromCharCode((index % 26) + "A".charCodeAt(0)) + col;
    index = Math.floor(index / 26);
  }
  return col;
}

export function expandRange(range) {
  // Split the range into start and end parts
  const [start, end] = range.split(":");

  // Extract row and column indices from start and end
  const startCol = start.match(/[A-Z]+/)[0];
  const startRow = parseInt(start.match(/\d+/)[0]);
  const endCol = end.match(/[A-Z]+/)[0];
  const endRow = parseInt(end.match(/\d+/)[0]);

  // Create an array to hold the cell references
  const cells = [];

  // Convert column letters to numeric indices (A=1, B=2, ...)
  const startColIndex = colToIndex(startCol);
  const endColIndex = colToIndex(endCol);

  // Generate the list of cells in the range
  for (let row = startRow; row <= endRow; row++) {
    for (let col = startColIndex; col <= endColIndex; col++) {
      const cell = indexToCol(col) + row;
      cells.push(cell);
    }
  }

  return cells;
}

export async function colorCells(cells: string[], colors: string[], activeWorksheet: string) {
  return await Excel.run({ delayForCellEdit: true }, async (context) => {
    const worksheet = context.workbook.worksheets.getItem(activeWorksheet);
    for (let i = 0; i < cells.length; i++) {
      const cellAddress = cells[i].split("!")[1];
      if (cellAddress.includes(":")) {
        const expandedCells = expandRange(cellAddress);
        for (let j = 0; j < expandedCells.length; j++) {
          let rangeToHighlight = worksheet.getRange(expandedCells[j]);
          if (colors[i] === null) {
            rangeToHighlight.format.fill.clear();
          } else {
            rangeToHighlight.format.fill.color = colors[i];
          }
        }
      } else {
        let rangeToHighlight = worksheet.getRange(cellAddress);
        if (colors[i] === null) {
          rangeToHighlight.format.fill.clear();
        } else {
          rangeToHighlight.format.fill.color = colors[i];
        }
      }
    }
    await context.sync();
  }).catch((error) => {
    console.log("colorCells crashed with error:", error);
    console.log("inputs were (cells):", cells);
    console.log("inputs were (colors):", colors);
    return colors;
  });
}

export function colorCellsInSingleColor(cells: string[], color: string, activeWorksheet: string) {
  return colorCells(cells, Array(cells.length).fill(color), activeWorksheet);
}

export async function isWorksheetEmpty(worksheetName: string) {
  return Excel.run({ delayForCellEdit: true }, async (context) => {
    const activeWorksheet = context.workbook.worksheets.getItem(worksheetName);
    const usedRange = activeWorksheet.getUsedRange();
    usedRange.load("rowCount");
    await context.sync();
    return usedRange.rowCount === 1;
  });
}

export function selectRange(rangeString: string) {
  Excel.run({ delayForCellEdit: true }, async (context) => {
    let range = context.workbook.worksheets.getActiveWorksheet().getRange(rangeString);
    range.select();
    await context.sync();
  });
}

export async function replaceCellsData(
  cellsContentMap: { [key: string]: string },
  cellsFormatsMap: { [key: string]: string }
) {
  // TODO: maps are received in a relative format, like this: {"AAPL!B9:H9": "=CELL[0,-2]-CELL[0,-1]"}
  // We need to convert it to a new dictionary where each key is an absolute cell address and each value is an
  // absolute formula. For example, the above map would be converted to {"AAPL!B9": "=AAPL!B7-AAPL!B8", "AAPL!C9": "=AAPL!C7-AAPL!C8", ...}
  // Then, we should take the huge JSON payload you get from getWorkbookData, and change the formulas in it to the new formulas
  // Finally, we should call insertData with the updated data
  console.log("TO DELETE - Replacing cells content with:", cellsContentMap);
  console.log("TO DELETE - Replacing cells formats with:", cellsFormatsMap);
}

export async function createNewWorksheetIfNotExist(worksheetName: string) {
  return Excel.run({ delayForCellEdit: true }, async (context) => {
    let worksheet = context.workbook.worksheets.getItemOrNullObject(worksheetName);
    await context.sync();
    if (worksheet.isNullObject) {
      worksheet = context.workbook.worksheets.add(worksheetName);
      await context.sync();
    }
  });
}
