import React from "react";
import Button from "@mui/material/Button";

interface WizzoFooterButtonProps {
  buttonText: string;
  buttonIcon: React.ReactNode;
  action: () => void;
}

export default function WizzoFooterButton({ buttonText, buttonIcon, action }: WizzoFooterButtonProps) {
  return (
    <Button
      size="small"
      variant="outlined"
      sx={{
        borderRadius: "10px",
        borderWidth: "0px",
        paddingLeft: "8px",
        textTransform: "none",
        backgroundColor: "#42bdff",
        color: "white",
      }}
      startIcon={buttonIcon}
      onClick={action}
    >
      <p style={{ margin: "0px", padding: "0px", fontSize: "10px" }}>
        <b>{buttonText}</b>
      </p>
    </Button>
  );
}
