import * as React from "react";
import { useEffect } from "react";

import { makeStyles } from "@fluentui/react-components";

import Visualizer from "./Visualizer";
import { addWorkbookEventListener } from "../utils/excelUtils";
import { getHighlights } from "../utils/utils";

const visualizersDescriptions = {
  hardcoded: "In-model cells with hardcoded values",
  assumptions: "Cells that are used as assumptions in the model",
  patternBreaking: "Formula cells that break the row pattern",
};

const useStyles = makeStyles({
  panel: {
    borderRadius: "10px",
    leftPadding: "10px",
    marginTop: "4px",
  },
});

export default function VisualizersPanel({ activeWorksheet, addColors, removeColors }) {
  const [hardcodedCells, setHardcodedCells] = React.useState([]);
  const [assumptionsCells, setAssumptionsCells] = React.useState([]);
  const [patternBreakingCells, setPatternBreakingCells] = React.useState([]);
  const [waitForHighlights, setWaitForHighlights] = React.useState(true);
  const classes = useStyles();

  const updateHighlights = () => {
    setWaitForHighlights(true);
    getHighlights().then((cells) => {
      if (cells.length === 0) {
        setWaitForHighlights(false);
        return;
      }
      setHardcodedCells(cells["HardCoded"]);
      setAssumptionsCells(cells["Assumptions"]);
      setPatternBreakingCells(cells["FormulaMisalignmentIssue"]);
      setWaitForHighlights(false);
    });
  };

  useEffect(() => {
    addWorkbookEventListener(updateHighlights);
  }, []);

  useEffect(updateHighlights, []);

  const hardcodedCellsInActiveWorksheet = hardcodedCells.filter((cell: string) => cell.includes(activeWorksheet));
  const assumptionsCellsInActiveWorksheet = assumptionsCells.filter((cell: string) => cell.includes(activeWorksheet));
  const patternBreakingCellsInActiveWorksheet = patternBreakingCells.filter((cell: string) =>
    cell.includes(activeWorksheet)
  );

  return (
    <div className={classes.panel}>
      <div>
        <Visualizer
          title="Pattern Disruption"
          cells={patternBreakingCellsInActiveWorksheet}
          description={visualizersDescriptions.patternBreaking}
          color="#7393b3"
          activeWorksheet={activeWorksheet}
          wait={waitForHighlights}
          addColorsLayer={addColors}
          removeColorsLayer={removeColors}
        />
        <Visualizer
          title="Hardcoded Cells"
          cells={hardcodedCellsInActiveWorksheet}
          description={visualizersDescriptions.hardcoded}
          color="#0096ff"
          activeWorksheet={activeWorksheet}
          wait={waitForHighlights}
          addColorsLayer={addColors}
          removeColorsLayer={removeColors}
        />
        <Visualizer
          title="Assumptions"
          cells={assumptionsCellsInActiveWorksheet}
          description={visualizersDescriptions.assumptions}
          color="#ff8c00"
          activeWorksheet={activeWorksheet}
          wait={waitForHighlights}
          addColorsLayer={addColors}
          removeColorsLayer={removeColors}
        />
      </div>
    </div>
  );
}
