import * as React from "react";
import { makeStyles } from "@fluentui/react-components";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import { insertData } from "../utils/excelUtils";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles({
  formulas_comp: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginTop: "10px",
    marginLeft: "10px",
  },
});

function IdentifiedFormulaItem({ itemName, formula, checkedFormulas, setCheckedFormulas }) {

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setCheckedFormulas([...checkedFormulas, { itemName, formula }]);
    } else {
      setCheckedFormulas(checkedFormulas.filter((item) => item.itemName !== itemName));
    }
  };

  return (
    <Tooltip title={formula}>
      <div style={{ margin: "2px" }}>
        <Checkbox sx={{ margin: "0px", padding: "0px" }} defaultChecked={true} onChange={handleCheckboxChange}/>
        {itemName}
      </div>
    </Tooltip>
  );
}

export function FormulaInference({ activeWorksheet }) {
  const styles = useStyles();
  const [isWaiting, setIsWaiting] = React.useState(false);
  const [formulas, setFormulas] = React.useState([
    { itemName: "Gross Profit", formula: "Revenue-Cost Of Revenue" },
    { itemName: "Gross Profit Margin", formula: "Gross Profit/Revenue" },
    { itemName: "Cost And Expenses", formula: "Cost Of Revenue + Operating Expenses" },
    { itemName: "EBITDA", formula: "Revenue - Cost And Expenses + Depreciation And Amortization" },
    { itemName: "EBITDA Ratio", formula: "EBITDA/Revenue" },
    { itemName: "Operating Income", formula: "Revenue - Cost And Expenses" },
    { itemName: "Operating Income Margin", formula: "Operating Income/Revenue" },
    { itemName: "Income Before Tax", formula: "Operating Income + Total Other Income Expenses Net" },
    { itemName: "Net Income", formula: "Income Before Tax - Income Before Tax" },
    { itemName: "Net Income Margin", formula: "Net Income/Revenue" },
    { itemName: "Earnings Per Share", formula: "Net Income/Weighted Average Shares Outstanding" },
    { itemName: "Earnings Per Share Diluted", formula: "Net Income/Weighted Average Shares Outstanding Diluted" },
  ]);
  const [checkedFormulas, setCheckedFormulas] = React.useState(formulas.filter(() => true));

  const loadFormulas = async () => {
    let data = require("./appleHistoricalsWithFormulas.json");
    setIsWaiting(true);
    await insertData(data["ag-grid"], activeWorksheet);
    const leftFormulas = formulas.filter((formula) => !checkedFormulas.includes(formula));
    setFormulas(leftFormulas);
    setCheckedFormulas([]);
    setTimeout(() => {
      setIsWaiting(false);
    }, 1000);
  };

  return (
    <div className={styles.formulas_comp}>
      <p style={{ margin: "0px" }}>
        <b>Identified Formulas</b>
      </p>
      <div style={{ fontSize: "14px", alignItems: "left" }}>
        {formulas.map((formula) => (
          <IdentifiedFormulaItem
            itemName={formula.itemName}
            formula={formula.formula}
            checkedFormulas={checkedFormulas}
            setCheckedFormulas={setCheckedFormulas}
          />
        ))}
      </div>
      {isWaiting ? (
        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
          <CircularProgress />
        </div>
      ) : (
        <button
          style={{
            color: "white",
            backgroundColor: "#004367",
            borderRadius: "25px",
            border: "0px",
            width: "40%",
            height: "34px",
            marginLeft: "auto",
            marginRight: "auto",
            cursor: "pointer",
          }}
          onClick={loadFormulas}
        >
          Replace values with formulas
        </button>
      )}
    </div>
  );
}
