import React from "react";
import Issue from "../../issue/Issue";
import { useCommonStyles } from "../../../../reusedComponents/commonStyles";

export default function ListBody({ issues, dismissIssue, markResolved, undo }) {
    const styles = useCommonStyles();
    return (
        <ul className={styles.noBulletpoints}>
        {issues.map((issue) => (
          <li key={issue.id}>
            {<Issue issue={issue} dismissIssue={dismissIssue} markResolved={markResolved} undo={undo} />}
          </li>
        ))}
      </ul>
    );
}