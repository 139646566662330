/* eslint-disable no-undef */
/* eslint-disable react/prop-types */

import * as React from "react";
import { makeStyles } from "@fluentui/react-components";
import TileSubmissionButton from "../../reusedComponents/TileSubmissionButton";
import RadioButtonGroup from "../../reusedComponents/RadioButtonGroup";
import WizzoMultiSelect from "../../reusedComponents/WizzoMultiSelect";

const useStyles = makeStyles({
  alerting_tile: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginTop: "10px",
    marginLeft: "10px",
    marginRight: "10px",
  },
  alert_item: {
    backgroundColor: "#f9f9fad0",
    borderRadius: "8px",
    border: "1px solid #004367",
    padding: "12px",
    marginBottom: "8px",
    position: "relative",
    transition: "all 0.2s",
    "&:hover": {
      backgroundColor: "#f0f0f0",
      boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
    },
  },
  alert_header: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  header_row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  delete_button: {
    position: "absolute",
    top: "12px",
    right: "12px",
    border: "none",
    background: "none",
    cursor: "pointer",
    color: "#cc0000",
    padding: "4px 8px",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    height: "24px",
    minWidth: "24px",
    opacity: 0.7,
    transition: "all 0.2s",
    "&:hover": {
      opacity: 1,
      backgroundColor: "#fff1f0",
    },
  },
  company_group: {
    marginBottom: "16px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
    overflow: "hidden",
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "translateY(-2px)",
    },
  },
  company_header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 16px",
    backgroundColor: "#f0f7ff",
    cursor: "pointer",
    borderBottom: "1px solid #e0e0e0",
    "&:hover": {
      backgroundColor: "#e6f2ff",
    },
  },
  company_title: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    "& h4": {
      margin: 0,
      fontSize: "16px",
      fontWeight: "600",
      color: "#2c5282",
    },
  },
  alert_count: {
    fontSize: "14px",
    color: "#4a5568",
  },
  company_alerts: {
    padding: "8px 16px",
    animation: "slideDown 0.2s ease-out",
  },
  "@keyframes slideDown": {
    from: {
      opacity: 0,
      transform: "translateY(-10px)",
    },
    to: {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
  alert_badge: {
    padding: "4px 8px",
    borderRadius: "12px",
    fontSize: "12px",
    fontWeight: "500",
    backgroundColor: "#e6ffed",
    color: "#1a7f37",
    border: "1px solid #2da44e",
  },
  badge_active: {
    backgroundColor: "#e6ffed",
    color: "#1a7f37",
    border: "1px solid #2da44e",
  },
  alert_rule: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },
  alert_details: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginTop: "8px",
    padding: "8px",
    backgroundColor: "#fafbfc",
    borderRadius: "6px",
    width: "100%",
    boxSizing: "border-box",
  },
  detail_row: {
    display: "flex",
    flexDirection: "column",
    padding: "12px",
    borderBottom: "1px solid #eee",
    width: "100%",
    boxSizing: "border-box",
    position: "relative",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  detail_content: {
    display: "flex",
    gap: "16px",
    flexWrap: "wrap",
  },
  detail_item: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    minWidth: "200px",
    flex: "1 1 auto",
  },
  detail_label: {
    fontSize: "11px",
    color: "#666",
    textTransform: "uppercase",
  },
  detail_value: {
    fontSize: "13px",
    color: "#24292f",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  stats_container: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "10px",
  },
  stat_item: {
    textAlign: "center",
  },
  stat_value: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  stat_label: {
    display: "block",
    marginTop: "5px",
  },
  rules_summary: {
    display: "flex",
    flexWrap: "wrap",
    gap: "6px",
    marginTop: "8px",
    marginBottom: "8px",
  },
  expand_button: {
    background: "none",
    border: "none",
    cursor: "pointer",
    color: "#666",
    padding: "4px 8px",
    "&:hover": {
      color: "#000",
    },
  },
  company_badge: {
    padding: "4px 8px",
    borderRadius: "12px",
    fontSize: "12px",
    fontWeight: "500",
    backgroundColor: "#f5f0ff",
    color: "#5a2ca0",
    border: "1px solid #8250df",
  },
  rules_and_companies_summary: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginTop: "8px",
    marginBottom: "8px",
  },
  badge_group: {
    display: "flex",
    flexWrap: "wrap",
    gap: "6px",
  },
  badge_label: {
    fontSize: "11px",
    color: "#666",
    textTransform: "uppercase",
    marginBottom: "2px",
  },
  company_name: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#2c5282",
  },
  alert_delete_button: {
    border: "none",
    background: "none",
    cursor: "pointer",
    color: "#666",
    padding: "4px",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "20px",
    height: "20px",
    transition: "all 0.2s",
    marginLeft: "8px",
    "&:hover": {
      backgroundColor: "#fff1f0",
      color: "#cc0000",
    },
  },
  ticket_delete_button: {
    position: "absolute",
    top: "12px",
    right: "12px",
    border: "none",
    background: "none",
    cursor: "pointer",
    color: "#666",
    padding: "6px",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "28px",
    height: "28px",
    minWidth: "28px",
    transition: "all 0.2s",
    zIndex: 1,
    "&:hover": {
      backgroundColor: "#fff1f0",
      color: "#cc0000",
    },
    "&:disabled": {
      cursor: "not-allowed",
      opacity: 0.7,
    },
  },
  loading_spinner: {
    display: "inline-block",
    animation: "spin 1s linear infinite",
  },
  "@keyframes spin": {
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(360deg)" },
  },
});

interface Alert {
  id: string;
  company: string;
  trigger: string;
  rule: string;
  notification_method: string;
  threshold?: number;
  threshold_unit?: string;
  status: string;
  created_at: string;
}

interface Trigger {
  id: string;
  name: string;
  requires_threshold: boolean;
  threshold_unit?: string;
  description?: string;
}

interface TriggerMap {
  [category: string]: Trigger[];
}

interface AlertingProps {
  activeWorksheet: any;
  runTileActionHook: (action: string, data: any) => Promise<any>;
}

interface AlertItemProps {
  alerts: Alert[];
  onBatchDelete: (ids: string[]) => void;
  companies: string[];
}

const AlertItem = React.memo(({ alerts, onBatchDelete, companies }: AlertItemProps) => {
  const styles = useStyles();
  const [showCompanies, setShowCompanies] = React.useState(false);
  const [showRules, setShowRules] = React.useState(false);
  const [deletingAlerts, setDeletingAlerts] = React.useState<string[]>([]);

  if (!alerts || alerts.length === 0) return null;

  const company = alerts[0].company;
  const isAllCompanies = company === "All Companies";
  const companyCount = isAllCompanies ? companies.length - 1 : 1;
  const affectedCompanies = isAllCompanies ? companies.filter((c) => c !== "All Companies") : [company];

  const handleDelete = () => {
    console.log("Delete button clicked");
    console.log("Company:", company);
    console.log("Alerts to delete:", alerts);
    const alertIds = alerts.map((alert) => alert.id);
    console.log("Alert IDs to delete:", alertIds);
    setDeletingAlerts((prev) => [...prev, ...alertIds]);
    onBatchDelete(alertIds);
  };

  return (
    <div className={styles.alert_item}>
      <div className={styles.alert_header}>
        <div className={styles.header_row}>
          <div className={styles.company_title}>
            <span className={styles.company_name}>{company}</span>
            <span className={styles.alert_count}>
              {isAllCompanies
                ? `(${companyCount} Companies, ${alerts.length} Rules)`
                : `(${alerts.length} ${alerts.length === 1 ? "Alert" : "Alerts"})`}
            </span>
          </div>
          <button
            onClick={handleDelete}
            className={styles.ticket_delete_button}
            title="Delete all alerts in this group"
            disabled={alerts.some((alert) => deletingAlerts.includes(alert.id))}
          >
            {alerts.some((alert) => deletingAlerts.includes(alert.id)) ? (
              <span className={styles.loading_spinner}>⟳</span>
            ) : (
              "✕"
            )}
          </button>
        </div>

        <div className={styles.rules_and_companies_summary}>
          <div>
            <div
              className={styles.badge_label}
              onClick={() => setShowCompanies(!showCompanies)}
              style={{ cursor: "pointer" }}
            >
              Companies {showCompanies ? "▼" : "▶"}
            </div>
            {showCompanies && (
              <div className={styles.badge_group}>
                {affectedCompanies.map((companyName) => (
                  <span key={companyName} className={styles.company_badge}>
                    {companyName}
                  </span>
                ))}
              </div>
            )}
          </div>

          <div>
            <div className={styles.badge_label} onClick={() => setShowRules(!showRules)} style={{ cursor: "pointer" }}>
              Rules {showRules ? "▼" : "▶"}
            </div>
            {showRules && (
              <div className={styles.badge_group}>
                {alerts.map((alert) => (
                  <span key={alert.id} className={`${styles.alert_badge} ${styles.badge_active}`}>
                    {alert.rule}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

// Add displayName for debugging
AlertItem.displayName = "AlertItem";

// Add new interfaces for grouped alerts
interface GroupedAlerts {
  [company: string]: Alert[];
}

// Update the groupAlertsByCompany function to respect original grouping
const groupAlertsByCompany = (alerts: Alert[] | any): GroupedAlerts => {
  console.log("Starting groupAlertsByCompany with alerts:", alerts);

  if (!Array.isArray(alerts)) {
    console.warn("Expected alerts to be an array, got:", alerts);
    return {};
  }

  // Simple grouping by company, preserving original grouping
  const groups = alerts.reduce((groups: GroupedAlerts, alert: Alert) => {
    const company = alert.company;
    if (!groups[company]) {
      groups[company] = [];
    }
    groups[company].push(alert);
    return groups;
  }, {});

  console.log("Grouped alerts by company:", groups);
  return groups;
};

// Add AlertStats component back
const AlertStats = ({ alerts }: { alerts: Alert[] }) => {
  const styles = useStyles();
  const companies = new Set(alerts.map((a) => a.company)).size;
  const rules = new Set(alerts.map((a) => a.rule)).size;

  return (
    <div className={styles.stats_container}>
      <div className={styles.stat_item}>
        <span className={styles.stat_value}>{alerts.length}</span>
        <span className={styles.stat_label}>Total Alerts</span>
      </div>
      <div className={styles.stat_item}>
        <span className={styles.stat_value}>{companies}</span>
        <span className={styles.stat_label}>Companies</span>
      </div>
      <div className={styles.stat_item}>
        <span className={styles.stat_value}>{rules}</span>
        <span className={styles.stat_label}>Rules</span>
      </div>
    </div>
  );
};

export function Alerting({ activeWorksheet, runTileActionHook }: AlertingProps) {
  const styles = useStyles();

  // Add helper functions at the top
  const getAllTriggerIds = (triggers: TriggerMap): string[] => {
    const ids: string[] = [];
    Object.values(triggers).forEach((categoryTriggers) => {
      categoryTriggers.forEach((trigger) => {
        if (trigger.id) {
          ids.push(trigger.id);
        }
      });
    });
    console.log("Found trigger IDs:", ids);
    return ids;
  };

  const getTriggerDetails = (triggerId: string): Trigger | null => {
    for (const category in triggers) {
      const trigger = triggers[category].find((t) => t.id === triggerId);
      if (trigger) return trigger;
    }
    return null;
  };

  const getTriggerOptions = (triggers: TriggerMap): string[] => {
    const options = Object.values(triggers).flatMap((categoryTriggers) =>
      categoryTriggers.map((trigger) => trigger.name)
    );
    return ["All Rules", ...options];
  };

  const getTriggerIdByName = (triggerName: string, triggers: TriggerMap): string | undefined => {
    if (triggerName === "All Rules") {
      return "all_triggers";
    }
    for (const category in triggers) {
      const trigger = triggers[category].find((t) => t.name === triggerName);
      if (trigger) return trigger.id;
    }
    return undefined;
  };

  const getTriggerNameById = (triggerId: string, triggers: TriggerMap): string | undefined => {
    if (triggerId === "all_triggers") {
      return "All Rules";
    }
    for (const category in triggers) {
      const trigger = triggers[category].find((t) => t.id === triggerId);
      if (trigger) return trigger.name;
    }
    return undefined;
  };

  // Initialize with empty arrays
  const [companies, setCompanies] = React.useState<string[]>([]);
  const [alerts, setAlerts] = React.useState<Alert[]>([]);
  const [triggers, setTriggers] = React.useState<TriggerMap>({});
  const [selectedCompanies, setSelectedCompanies] = React.useState<string[]>([]);
  const [selectedRules, setSelectedRules] = React.useState<string[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isAddingAlert, setIsAddingAlert] = React.useState(false);
  const [notificationMethod, setNotificationMethod] = React.useState<string>("email");
  const [threshold, setThreshold] = React.useState<number>(0);

  // Fetch data in a single useEffect
  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Fetch companies
        const companiesResult = await runTileActionHook("get_companies", {
          wizzoFileID: activeWorksheet.id,
        });
        const companiesData = companiesResult?.data || [];

        // Add "All Companies" option
        const companiesWithAll = ["All Companies", ...companiesData];
        setCompanies(companiesWithAll);

        // Set "All Companies" as default selection
        setSelectedCompanies(["All Companies"]);

        // Fetch triggers
        const triggersResult = await runTileActionHook("get_triggers", {
          wizzoFileID: activeWorksheet.id,
        });

        let triggersData = triggersResult?.data?.data ||
          triggersResult?.data || {
            company_info: [
              { id: "ownership_change", name: "Ownership Structure Change", requires_threshold: false },
              { id: "management_change", name: "Management Team Change", requires_threshold: false },
            ],
          };

        setTriggers(triggersData);
        // Set "all_triggers" as default selection
        setSelectedRules(["all_triggers"]);

        // Fetch alerts
        const alertsResult = await runTileActionHook("get_alerts", {
          wizzoFileID: activeWorksheet.id,
        });
        console.log("Initial alerts fetch:", alertsResult);

        const alertsData = alertsResult?.data?.data || alertsResult?.data || [];
        if (Array.isArray(alertsData)) {
          console.log("Setting initial alerts to:", alertsData);
          setAlerts(alertsData);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [activeWorksheet.id, runTileActionHook]);

  // Update the refreshAlerts function to handle nested data
  const refreshAlerts = async () => {
    try {
      console.log("Refreshing alerts...");
      const alertsResult = await runTileActionHook("get_alerts", {
        wizzoFileID: activeWorksheet.id,
      });
      console.log("Alerts refresh result:", alertsResult);

      // Handle nested data structure
      const alertsData = alertsResult?.data?.data || alertsResult?.data || [];
      if (Array.isArray(alertsData)) {
        console.log("Setting alerts to:", alertsData);
        setAlerts(alertsData);
      } else {
        console.warn("Unexpected alerts data structure:", alertsData);
        setAlerts([]);
      }
    } catch (error) {
      console.error("Error refreshing alerts:", error);
    }
  };

  // Update addAlert to handle grouping correctly
  const addAlert = async () => {
    if (selectedCompanies.length === 0 || selectedRules.length === 0) return;

    setIsAddingAlert(true);
    try {
      console.log("Adding alert with selections:", {
        selectedCompanies,
        selectedRules,
        notificationMethod,
        threshold,
      });

      const triggersForAlert = selectedRules.includes("all_triggers") ? getAllTriggerIds(triggers) : selectedRules;

      // If "All Companies" is selected, create one alert with "All Companies"
      // Otherwise, create separate alerts for each company
      const companiesForAlert = selectedCompanies.includes("All Companies") ? ["All Companies"] : selectedCompanies;

      const thresholdRule = triggersForAlert
        .map((id) => getTriggerDetails(id))
        .find((trigger) => trigger?.requires_threshold);

      const requestData = {
        wizzoFileID: activeWorksheet.id,
        companies: companiesForAlert,
        triggers: triggersForAlert,
        notification_method: notificationMethod,
        threshold: thresholdRule ? threshold : undefined,
        threshold_unit: thresholdRule?.threshold_unit,
        create_separate: true, // Always create separate alerts
      };

      console.log("Sending add request with data:", requestData);
      const result = await runTileActionHook("add_alerts_batch", requestData);
      console.log("Add result:", result);

      if (result?.data) {
        console.log("Add successful, refreshing alerts...");
        await refreshAlerts();
      }
    } catch (error) {
      console.error("Error adding alerts:", error);
    } finally {
      setIsAddingAlert(false);
    }
  };

  // Change the handler to match the expected type
  const handleNotificationMethodChange = () => {
    setNotificationMethod((prev) => (prev === "email" ? "slack" : "email"));
  };

  // Update the handleBatchDelete function
  const handleBatchDelete = async (ids: string[]) => {
    try {
      console.log("handleBatchDelete called with ids:", ids);
      console.log("Current alerts before deletion:", alerts);

      if (!ids || ids.length === 0) {
        console.error("No alert IDs provided for deletion");
        return;
      }

      const validIds = ids.filter((id) => typeof id === "string" && id.length > 0);
      console.log("Filtered valid IDs:", validIds);

      if (validIds.length === 0) {
        console.error("No valid alert IDs to delete");
        return;
      }

      const result = await runTileActionHook("delete_alerts_batch", {
        alert_ids: validIds,
        wizzoFileID: activeWorksheet.id,
      });

      console.log("Delete result:", result);

      if (result?.status === "success") {
        console.log("Delete successful, refreshing alerts...");
        await new Promise((resolve) => setTimeout(resolve, 100));
        await refreshAlerts();
      } else {
        console.error("Failed to delete alerts:", result);
      }
    } catch (error) {
      console.error("Error in handleBatchDelete:", error);
    }
  };

  if (isLoading) {
    return (
      <div className={styles.alerting_tile}>
        <h3>Loading...</h3>
        <p>Please wait while we fetch the data...</p>
      </div>
    );
  }

  // Get details of the selected rule for conditional rendering of threshold input
  const selectedRuleDetails = selectedRules.length > 0 ? getTriggerDetails(selectedRules[0]) : null;

  console.log("Current alerts:", alerts);

  return (
    <div className={styles.alerting_tile} style={{ position: "relative" }}>
      <h3>Create New Alert</h3>

      {/* Company Selection */}
      <div>
        <p style={{ margin: "0px", marginBottom: "4px" }}>
          <b>Select Companies</b>
        </p>
        <WizzoMultiSelect
          options={companies}
          selectedValues={selectedCompanies}
          onSelectionChange={setSelectedCompanies}
        />
      </div>

      {/* Rule Selection */}
      <div>
        <p style={{ margin: "0px", marginBottom: "4px" }}>
          <b>Select Rules</b>
        </p>
        <WizzoMultiSelect
          options={getTriggerOptions(triggers)}
          selectedValues={selectedRules.map((id) => getTriggerNameById(id, triggers) || "")}
          onSelectionChange={(names) => {
            setSelectedRules(names.map((name) => getTriggerIdByName(name, triggers) || "").filter(Boolean));
          }}
        />
      </div>

      {/* Show threshold input only if the selected rule requires it */}
      {selectedRules.length > 0 && selectedRuleDetails?.requires_threshold && (
        <div style={{ marginBottom: "10px" }}>
          <p style={{ margin: "0px", marginBottom: "4px" }}>
            <b>Threshold {selectedRuleDetails.threshold_unit && `(${selectedRuleDetails.threshold_unit})`}</b>
          </p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="number"
              value={threshold}
              onChange={(e) => setThreshold(Number(e.target.value))}
              style={{
                width: "80px", // Reduced width
                height: "24px",
                padding: "0 8px",
                marginRight: "4px",
              }}
            />
            <span>{selectedRuleDetails.threshold_unit}</span>
          </div>
        </div>
      )}

      {/* Notification Method Selection */}
      <RadioButtonGroup
        title="Notification Method"
        options={[
          { value: "email", label: "Email" },
          { value: "slack", label: "Slack" },
        ]}
        name="notification_method"
        defaultValue={notificationMethod}
        handleOnClick={handleNotificationMethodChange}
      />

      {/* Add Alert Button */}
      <TileSubmissionButton
        onClick={
          selectedCompanies.length === 0 || selectedRules.length === 0 || isAddingAlert
            ? () => {} // Empty function when disabled
            : addAlert
        }
        buttonText={isAddingAlert ? "Adding..." : "Add Alert"}
      />

      {/* Existing Alerts List */}
      <h3>Active Alerts:</h3>
      <div>
        {!Array.isArray(alerts) || alerts.length === 0 ? (
          <p style={{ color: "#666", fontStyle: "italic" }}>No alerts created yet</p>
        ) : (
          Object.entries(groupAlertsByCompany(alerts))
            .sort(([companyA], [companyB]) => companyA.localeCompare(companyB))
            .map(([company, companyAlerts]) => (
              <AlertItem key={company} alerts={companyAlerts} onBatchDelete={handleBatchDelete} companies={companies} />
            ))
        )}
      </div>

      <AlertStats alerts={Array.isArray(alerts) ? alerts : []} />
    </div>
  );
}
