import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ListOpenCloseIcon } from "./ListOpenCloseIcon";
import Tooltip from "@mui/material/Tooltip";

interface CheckboxSelectionProps {
  selectionOptions: string[];
  tooltips?: string[];
  title: string;
}

export default function CheckboxMultiSelection({ selectionOptions, tooltips = [], title }: CheckboxSelectionProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <p style={{ margin: "0", padding: "0", fontSize: "14px" }}>
          <b>{title}</b>
        </p>
        <ListOpenCloseIcon isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
      {isOpen && (
        <FormGroup sx={{ margin: "0", padding: "0", paddingLeft: "12px" }}>
          {selectionOptions?.map((option, index) => (
            <Tooltip key={option} title={tooltips[index] || "select"}>
              <FormControlLabel
                sx={{ margin: "0", padding: "0" }}
                control={<Checkbox sx={{ margin: "0", padding: "0" }} defaultChecked={true} />}
                label={<p style={{ margin: "0", padding: "0", fontSize: "14px" }}>{option}</p>}
              />
            </Tooltip>
          ))}
        </FormGroup>
      )}
    </div>
  );
}
