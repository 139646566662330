import * as React from "react";

import QuickFix from "./utils/QuickFix";
import { useCommonStyles } from "../../../reusedComponents/commonStyles";

export default function IssueContent({ issue, markResolved }) {
  const styles = useCommonStyles();
  return (
    <div className={styles.item__content}>
      <p style={{ alignItems: "center" }}>{issue.description}</p>
      {issue.status === "OPEN" && Object.keys(issue.quickFixSuggestion).length > 0 && (
        <QuickFix issue={issue} markResolved={markResolved} />
      )}
    </div>
  );
}
