import * as React from "react";
import { makeStyles } from "@fluentui/react-components";
import SendIcon from "@mui/icons-material/Send";

const useStyles = makeStyles({
  search: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "10px",
    marginTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "90%",
    borderRadius: "10px",
    border: "1px solid #808080",
    alignItems: "center",
    padding: "8px",
    backgroundColor: "white",
  },
  search_box: {
    padding: "4px",
    fontSize: "15px",
    border: "none",
    outline: "none",
  },
  send_icon: {
    color: "#42bdff",
    cursor: "pointer",
  },
});

interface SearchBarProps {
  placeholder: string;
}

export default function SearchBar({ placeholder }: SearchBarProps) {
  const styles = useStyles();
  return (
    <div className={styles.search}>
      <input className={styles.search_box} type="text" placeholder={placeholder} />
      <SendIcon className={styles.send_icon} />
    </div>
  );
}
