/* eslint-disable no-undef */
/* eslint-disable react/prop-types */

import * as React from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

import { ListOpenCloseIcon } from "../../reusedComponents/ListOpenCloseIcon";
import { LoadingSpinner } from "../../reusedComponents/LoadingSpinner";
import TileSubmissionButton from "../../reusedComponents/TileSubmissionButton";
import IntNumberInput from "../../reusedComponents/IntNumberInput";
import RadioButtonGroup from "../../reusedComponents/RadioButtonGroup";
import barronsLogo from "./assets/barrons-logo.png";
import forbesLogo from "./assets/forbes-logo.png";
import bloombergLogo from "./assets/bloomberg-logo.png";

function PartTitle({ title }) {
  return (
    <p style={{ margin: "0px", marginTop: "6px", marginBottom: "6px", fontSize: "16px" }}>
      <b>{title}</b>
    </p>
  );
}

function OwnValuesRow({ onAdd }) {
  const rowStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    gap: "2px",
    marginLeft: "6px",
    marginRight: "6px",
    marginBottom: "6px",
    justifyContent: "space-between",
  };
  const inputStyle: React.CSSProperties = {
    width: "15%",
    height: "20px",
    borderRadius: "4px",
    border: "1px solid #004367",
    textAlign: "center",
  };
  const textStyle: React.CSSProperties = {
    margin: "0px",
    marginTop: "4px",
    fontSize: "13px",
  };
  return (
    <div style={rowStyle}>
      <p style={textStyle}>Value (%):</p>
      <input style={inputStyle} type="number" placeholder="6.0" />
      <p style={textStyle}>Period:</p>
      <input style={inputStyle} type="number" min="2025" max="2029" step="1" />
      <p style={textStyle}> - </p>
      <input style={inputStyle} type="number" min="2025" max="2029" step="1" />
      <div onClick={onAdd}>
        <AddCircleIcon fontSize="small" sx={{ color: "#004367", cursor: "pointer" }} />
      </div>
    </div>
  );
}

function ValuesSettings({ forecastSelected }) {
  if (forecastSelected === "own") {
    const [wasClicked, setWasClicked] = React.useState(false);
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "8px" }}>
        <OwnValuesRow onAdd={() => setWasClicked(true)} />
        {wasClicked && <OwnValuesRow onAdd={() => {}} />}
      </div>
    );
  } else if (forecastSelected === "linreg") {
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "8px" }}>
        <p
          style={{
            color: "green",
            fontSize: "12px",
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
            textAlign: "center",
          }}
        >
          Great Fit - Linear Regression predicts the metric with 91% accuracy
        </p>
      </div>
    );
  } else if (forecastSelected === "avg") {
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "8px" }}>
        <label style={{ marginLeft: "6px", fontSize: "12px" }}># of years:</label>
        <input style={{ width: "15%", fontSize: "13px" }} type="number" min={1} max={20} step={1}></input>
      </div>
    );
  } else {
    return <div></div>;
  }
}

function Assumption({ name, recommendationsUsed, relevantSources }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [wasOpened, setWasOpened] = React.useState(false);
  const [forecastSelected, setForecastSelected] = React.useState("");

  React.useEffect(() => {
    if (isOpen) {
      setWasOpened(true);
    }
  }, [isOpen]);

  return (
    <div style={{ backgroundColor: "white", borderRadius: "10px", border: "0.5px solid rgb(201, 214, 222)" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p style={{ margin: "0px", marginTop: "4px", marginBottom: "4px", paddingLeft: "6px", fontSize: "14px" }}>
          {isOpen ? <b>{name}</b> : name}
        </p>
        {(!wasOpened || isOpen) && !recommendationsUsed ? (
          <BorderColorIcon fontSize="small" style={{ marginLeft: "6px", color: "#004367" }} />
        ) : (
          <CheckCircleIcon fontSize="small" style={{ marginLeft: "6px", color: "#004367" }} />
        )}
        <div style={{ marginTop: "2px", marginRight: "4px", marginLeft: "auto" }}>
          <ListOpenCloseIcon isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
      </div>
      {isOpen && (
        <div>
          {/** Relevant Information **/}
          {relevantSources && relevantSources[name] && (
            <div style={{ marginBottom: "10px" }}>
              <p style={{ paddingLeft: "6px", fontSize: "13px" }}>
                <b>Relevant Information</b>
              </p>
              <ul style={{ listStyleType: "none", margin: "0px", padding: "0px", marginBottom: "6px" }}>
                {relevantSources[name].map((article) => {
                  return (
                    <li
                      key={article.title}
                      style={{
                        marginLeft: "12px",
                        marginBottom: "8px",
                        padding: "0px",
                        paddingRight: "8px",
                        fontSize: "13px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "6px",
                          marginBottom: "6px",
                        }}
                      >
                        <img
                          style={{ height: "12px", color: "white", marginRight: "6px" }}
                          src={
                            article.source === "barrons-logo.png"
                              ? barronsLogo
                              : article.source === "forbes-logo.png"
                                ? forbesLogo
                                : bloombergLogo
                          }
                        />
                        <a href={article.link} target="_blank" rel="noreferrer">
                          <b>{article.title}</b>
                        </a>
                      </div>
                      <i> &quot;{article.quote}&quot; </i>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {/** Forecast Method **/}
          <div>
            <p style={{ margin: "0px", padding: "0px", marginBottom: "4px", paddingLeft: "6px", fontSize: "13px" }}>
              <b>Forecast Method</b>
            </p>
            <select
              name="selectedDataSource"
              style={{ height: "24px", display: "block", marginLeft: "auto", marginRight: "auto", marginBottom: "4px" }}
              onChange={(e) => setForecastSelected(e.target.value)}
            >
              <option value="" disabled selected>
                Select your option
              </option>
              <option value="own">Own Values</option>
              <option value="linreg">Linear Regression</option>
              <option value="avg">X Years Average</option>
              <option value="same">Same as Last Period</option>
            </select>
          </div>
          {/** Values Settings **/}
          <div>
            {forecastSelected !== "" && (
              <div>
                <ValuesSettings forecastSelected={forecastSelected} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

function AssumptionList({ idx, statement, statementAssumptions, relevantSources }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [recommendationsUsed, setRecommendationsUsed] = React.useState(false);

  return (
    <div key={idx}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p style={{ fontSize: "14px", margin: "2px", padding: "2px" }}>
          <b>{statement}</b>
        </p>
        <div style={{ cursor: "pointer" }} onClick={() => setRecommendationsUsed(true)}>
          <AutoAwesomeIcon fontSize="small" />
        </div>
        <div style={{ marginLeft: "auto" }}>
          <ListOpenCloseIcon isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
      </div>
      {isOpen && (
        <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          {statementAssumptions.map((assumption) => (
            <Assumption
              key={assumption}
              name={assumption}
              recommendationsUsed={recommendationsUsed}
              relevantSources={relevantSources}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export function AddForecasts({ activeWorksheet, runTileActionHook, insertDataHook }) {
  const [isAnnual, setIsAnnual] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(true);
  const [assumptions, setAssumptions] = React.useState({});
  const [relevantSources, setRelevantSources] = React.useState({});
  let horizonPickingString = "Number of " + (isAnnual ? "years" : "quarters") + " forward";

  React.useEffect(() => {
    runTileActionHook("get_assumptions", {}).then((result) => {
      setAssumptions(result.data.assumptions);
      setRelevantSources(result.data.relevantSources);
      setIsLoading(false);
    });
  }, []);

  const loadForecasts = async () => {
    const result = await runTileActionHook("add_forecasts", {
      isAnnual: isAnnual,
      wizzoFileID: activeWorksheet.id,
    });
    await insertDataHook(result.data, "Add Forecasts", "Added " + (isAnnual ? "annual" : "quarterly") + " forecasts");
  };

  return (
    <div>
      {/* Set Frequency */}
      <RadioButtonGroup
        title="Data frequency"
        options={[
          { value: "q", label: "Quarterly" },
          { value: "a", label: "Annual" },
        ]}
        name="periods"
        defaultValue="a"
        handleOnClick={() => setIsAnnual(!isAnnual)}
      />

      {/* Set Horizon Length */}
      <IntNumberInput label={horizonPickingString} name="horizon" defaultValue={3} min={1} max={10} />

      {/* Set Required Assumptions */}
      <PartTitle title="Required Assumptions" />
      <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        {isLoading && <LoadingSpinner />}
        {assumptions &&
          Object.keys(assumptions).map((statement, idx) => (
            <AssumptionList
              key={statement}
              idx={idx}
              statement={statement}
              statementAssumptions={assumptions[statement]}
              relevantSources={relevantSources}
            />
          ))}
      </div>

      <TileSubmissionButton onClick={loadForecasts} buttonText="Add Forecasts" />
    </div>
  );
}
