import * as React from "react";
import HierarchicalList from "./interfaces/HierarchicalList";
import IssueObject from "../interfaces/IssueObject";

const inIssuesList = (issue, list) => {
  return list.some(
    (item) => issue.sheet_name === item.sheet_name && issue.cellsRange === item.cellsRange && issue.title === item.title
  );
};

const getOpenIssuesInActiveWorksheet = (issues, activeWorksheet) => {
  const resolvedIssues = issues.filter((issue) => issue.status === "RESOLVED");
  const dismissedIssuesInSheet = issues.filter(
    (issue) => issue.status === "DISMISSED" && issue.sheet_name === activeWorksheet
  );
  const openIssuesToDisplay = issues.filter(
    (issue: IssueObject) => !inIssuesList(issue, resolvedIssues) && !inIssuesList(issue, dismissedIssuesInSheet)
  );
  const openIssuesInSheet = openIssuesToDisplay.filter((issue: IssueObject) => issue.sheet_name === activeWorksheet);
  return openIssuesInSheet;
};

export default function OpenIssuesList({
  issues,
  activeWorksheet,
  dismissIssue,
  markResolved,
  undo,
  addColorsLayer,
  removeColorsLayer,
  updateIssues,
}) {
  return (
    <HierarchicalList
      listName="Open Errors"
      issues={getOpenIssuesInActiveWorksheet(issues, activeWorksheet)}
      dismissIssue={dismissIssue}
      markResolved={markResolved}
      undo={undo}
      activeWorksheet={activeWorksheet}
      addColorsLayer={addColorsLayer}
      removeColorsLayer={removeColorsLayer}
      updateIssues={updateIssues}
    />
  );
}
