import React from "react";
import { makeStyles } from "@fluentui/react-components";
import Button from "@mui/material/Button";

const useStyles = makeStyles({
  feedback_popup: {
    zIndex: "2",
    width: "80%",
    backgroundColor: "white",
    borderRadius: "6px",
    border: "1px solid #bdbdbd",
    "&:hover, &:focus": {
      border: "2px solid #bdbdbd",
    },
    padding: "10px",
    position: "fixed",
    bottom: "10%",
    right: "5%",
  },
  welcome_msg: {
    fontFamily: "sans-serif",
    fontSize: "14px",
  },
  feedback_textarea: {
    width: "90%",
    height: "50%",
    borderRadius: "2px",
    border: "1px solid #bdbdbd",
    padding: "10px",
    fontFamily: "sans-serif",
  },
  actions_container: {
    display: "flex",
    flexDirection: "row",
    marginTop: "4px",
  },
});

export default function FeedbackPopup({ setIsFeedbackOpen, userName, userEmail }) {
  const styles = useStyles();
  const feedbackInititalState = "Let us know what do you think, it goes directly into our Slack!";
  const [sent, setSent] = React.useState(false);
  const [feedback, setFeedback] = React.useState(feedbackInititalState);

  const isValidFeedback = () => {
    return feedback !== feedbackInititalState && feedback !== "";
  };

  const buttonStyles = {
    close_button: {
      borderRadius: "10px",
      borderWidth: "1px",
      textTransform: "none",
      color: "gray",
      borderColor: "gray",
    },
    send_button: {
      borderRadius: "10px",
      borderWidth: "1px",
      textTransform: "none",
      marginLeft: "auto",
      color: "white",
      backgroundColor: isValidFeedback() ? "#004367" : "#BDBDBD",
    },
  };

  const handleSendingFeedback = () => {
    if (!isValidFeedback()) {
      return;
    }
    const feedbackMsg = `Feedback from ${userEmail}: ${feedback}`;
    setSent(true);
    try {
      fetch("https://hooks.slack.com/services/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", {
        method: "POST",
        headers: {},
        body: JSON.stringify({ text: feedbackMsg }),
      });
    } catch (error) {
      console.error("Error sending feedback, close box anyway. Error was:", error);
    }
    setTimeout(() => {
      setIsFeedbackOpen(false);
    }, 1000);
  };

  const handleClose = () => {
    setIsFeedbackOpen(false);
  };

  return (
    <div className={styles.feedback_popup}>
      <p className={styles.welcome_msg}>Hello {userName}!</p>
      <textarea
        placeholder={feedbackInititalState}
        className={styles.feedback_textarea}
        onChange={(e) => setFeedback(e.target.value)}
      />
      <div className={styles.actions_container}>
        <Button size="small" variant="outlined" sx={buttonStyles.close_button} onClick={handleClose}>
          Close
        </Button>
        <Button size="small" variant="contained" sx={buttonStyles.send_button} onClick={handleSendingFeedback}>
          {sent ? "Thanks!" : "Send"}
        </Button>
      </div>
    </div>
  );
}
