import * as React from "react";

import { colorCodes, categoriesText } from "../../../utils/utils";
import HierarchicalListHeader from "../headers/HierarchicalListHeader";
import CategoryList from "./CategoryList";


export default function HierarchicalList({
  listName,
  issues,
  dismissIssue,
  markResolved,
  undo,
  activeWorksheet,
  addColorsLayer,
  removeColorsLayer,
  updateIssues,
}) {
  const issuesCategories = new Set(issues.map((issue) => issue.category));

  return (
    <div>
      <HierarchicalListHeader listName={listName} list={issues} onRefresh={updateIssues}/>
      <ul style={{ padding: "0px", margin: "0px", marginLeft: "10px" }}>
        {Array.from(issuesCategories).sort().map((category: string) => (
          <li key={category} style={{ listStyleType: "none", padding: "0px", margin: "0px" }}>
          <CategoryList
            listName={categoriesText[category]}
            highlightingColor={colorCodes[category]}
            issues={issues.filter((issue) => issue.category === category)}
            dismissIssue={dismissIssue}
            markResolved={markResolved}
            undo={undo}
            activeWorksheet={activeWorksheet}
            addColorsLayer={addColorsLayer}
            removeColorsLayer={removeColorsLayer}
          />
          </li>
        ))}
      </ul>
    </div>
  );
}
