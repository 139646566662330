import React from "react";
import { Select, MenuItem, Chip, Box, FormControl } from "@mui/material";

interface WizzoMultiSelectProps {
  title?: string;
  options: string[];
  selectedValues: string[];
  onSelectionChange: (newValues: string[]) => void;
}

export default function WizzoMultiSelect({ title, options, selectedValues, onSelectionChange }: WizzoMultiSelectProps) {
  const handleChange = (event) => {
    const value = event.target.value;
    onSelectionChange(typeof value === "string" ? value.split(",") : value);
  };

  const handleDelete = (valueToDelete: string) => {
    onSelectionChange(selectedValues.filter((value) => value !== valueToDelete));
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      {title && (
        <p style={{ margin: "0", padding: "0", fontSize: "14px" }}>
          <b>{title}</b>
        </p>
      )}
      <FormControl sx={{ mb: 2, display: "flex", flexDirection: "column", gap: "10px" }}>
        <Select
          multiple
          value={selectedValues}
          onChange={handleChange}
          onClose={() => {}}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              e.stopPropagation();
              e.preventDefault();
              e.currentTarget.blur();
            }
          }}
          MenuProps={{
            disablePortal: true,
            PaperProps: {
              style: {
                maxHeight: 200,
                maxWidth: "100%",
                width: "80%",
              },
              onClick: (e) => e.stopPropagation(),
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            sx: {
              "& .MuiMenu-paper": {
                position: "absolute",
                width: "80%",
              },
            },
          }}
          sx={{
            width: "100%",
            fontSize: "13px",
            backgroundColor: "#fbfbfb",
            borderRadius: "12px",
            "& .MuiSelect-select": {
              padding: "8px",
              minHeight: "20px !important",
              lineHeight: "1.2",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
            "& .MuiSelect-icon": {
              cursor: "pointer",
            },
            "& .MuiMenuItem-root": {
              fontSize: "14px",
              minHeight: "32px",
            },
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option}
              value={option}
              sx={{
                padding: "4px 8px",
                fontSize: "12px",
                minHeight: "18px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "wrap",
                "&:hover": {
                  backgroundColor: "#f5f5f5",
                },
              }}
            >
              {option}
            </MenuItem>
          ))}
        </Select>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
          {selectedValues.map((value) => (
            <Chip
              key={value}
              label={value}
              onDelete={() => handleDelete(value)}
              onMouseDown={(event) => {
                event.stopPropagation();
              }}
            />
          ))}
        </Box>
      </FormControl>
    </div>
  );
}
